import * as nope from 'yup'

import {
  ContactAttributeType,
  IContactField,
  IContactAttribute,
} from 'store/personalization/contactAttributes/selectors'
import uuid from 'uuid'
import { IHelloPageFieldResponse, IHelloPageResponse } from 'api/response'
import { notUndefined } from 'util/typeguards'
import { getValidatorFromAttribute } from 'components/ContactPanel/ContactAttributesValuesForm'

export interface IHelloPagesConfigProps {
  id?: number
}

export interface IField {
  id?: number
  order: number
  visible: boolean
  displayName?: string
  topLevelField: IContactField | undefined
  contactAttribute: IContactField | undefined
  defaultValue?: string
  key: string
  required?: boolean
}

export interface IHardCodedField extends IField {
  topLevelField: IContactField
}

export interface IImageUpload {
  id: number
  collegeId: string
  bucket: string
  key: string
  completed: boolean
  contentType: string
  href: string
  name: string
  public: boolean
  size: number
}

export interface IHelloPagesConfigFormData {
  title: string | undefined
  urlPath: string | undefined
  published: boolean
  formHeader: string | undefined
  formDescription: string | undefined
  userFields: IField[]
  hiddenFields: IField[]
  consentText: string | undefined
  confirmationMessage: string | undefined
  introScriptId: string | undefined
  iconUpload: IImageUpload | undefined
  formImageUpload: IImageUpload | undefined
}

interface ITestContextExtended extends nope.TestContext {
  from: { schema: nope.ObjectSchema; value: IField }[]
}

export const HelloPageConfigValidationSchema = (
  existingUrlPaths: string[],
  contactAttributes: IContactAttribute[]
) => {
  return nope.object().shape({
    title: nope
      .string()
      .max(100, 'Title must be 100 characters or less.')
      .trim()
      .required('Title cannot be blank.'),
    urlPath: nope
      .string()
      .test(
        'urlPaths-are-unique',
        'URL path must be globally unique. Try a different path.',
        (value: string) => !existingUrlPaths.includes(value)
      )
      .required('URL path is required.'),
    published: nope.boolean(),
    formHeader: nope
      .string()
      .max(100, 'Header must be 100 characters or less.')
      .notRequired(),
    formDescription: nope
      .string()
      .max(1000, 'Description must be 1000 characters or less.')
      .notRequired(),
    userFields: nope.array().of(
      nope.object().shape(
        {
          order: nope.number(),
          // All fields in this section are visible.
          visible: nope
            .boolean()
            .test(
              'user-fields-are-visible',
              'All user fields must be visible.',
              value => !!value
            ),
          required: nope.boolean(),
          displayName: nope
            .string()
            .trim()
            .required('All fields must have a name.'),
          // contactAttribute xor valid topLevelField
          topLevelField: nope.mixed().when('contactAttribute', {
            is: undefined,
            then: nope.object().shape({
              field: nope
                .string()
                .oneOf([
                  'name_first',
                  'name_last',
                  'name_preferred',
                  'phone',
                  'email',
                ])
                .required('Contact field is required.'),
            }),
            otherwise: undefined,
          }),
          // contactAttribute xor valid topLevelField
          contactAttribute: nope.mixed().when('topLevelField', {
            is: undefined,
            then: nope.object().shape({
              id: nope.number(),
              field: nope
                .string()
                .oneOf(contactAttributes.map(elem => elem.field))
                .required('Contact field is required.'),
            }),
            otherwise: undefined,
          }),
          // Validate defaultValue based on selected contactAttribute/topLevelField
          defaultValue: nope
            .mixed()
            .test(
              'valid-defaultValue',
              'Invalid per data type. Optional.',
              function(value) {
                if (!value) {
                  return nope
                    .string()
                    .notRequired()
                    .isValid(value)
                }
                /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                const { from } = this as ITestContextExtended
                const contactAttribute = from[0].value.contactAttribute
                const topLevelField = from[0].value.topLevelField
                // Default value for email doesn't make sense, but good to handle anyway.
                if (topLevelField?.field === 'email') {
                  return nope
                    .string()
                    .email()
                    .max(1000)
                    .notRequired()
                    .isValid(value)
                }
                if (
                  !contactAttribute ||
                  (topLevelField && topLevelField.field !== 'email')
                ) {
                  return nope
                    .string()
                    .notRequired()
                    .isValid(value)
                }
                return getValidatorFromAttribute(contactAttribute).isValid(
                  value
                )
              }
            ),
        },
        // noSortEdges: https://github.com/jquense/yup/issues/720
        // Without these, there are cyclical dependencies between interdependent
        // fields, and validation breaks.
        [
          ['topLevelField', 'contactAttribute'],
          ['contactAttribute', 'defaultValue'],
        ]
      )
    ),
    hiddenFields: nope.array().of(
      nope.object().shape(
        {
          order: nope.number(),
          // No fields in this section are visible
          visible: nope
            .boolean()
            .test(
              'hidden-fields-are-not-visible',
              'All hidden fields must not be visible.',
              value => !value
            ),
          required: nope.boolean(),
          displayName: nope
            .string()
            .trim()
            .required('All fields must have a name.'),
          // Hidden topLevelField doesn't make sense
          topLevelField: nope.string().default(undefined),
          contactAttribute: nope.object().shape({
            id: nope.number(),
            field: nope
              .string()
              .oneOf(contactAttributes.map(elem => elem.field))
              .required('Contact field is required.'),
          }),
          // Validate defaultValue based on selected contactAttribute
          defaultValue: nope
            .mixed()
            .test(
              'valid-defaultValue',
              'Invalid per data type. Optional.',
              function(value) {
                if (!value) {
                  return nope
                    .string()
                    .notRequired()
                    .isValid(value)
                }
                /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                const { from } = this as ITestContextExtended
                const contactAttribute = from[0].value.contactAttribute
                if (!contactAttribute) {
                  return nope
                    .string()
                    .notRequired()
                    .isValid(value)
                }
                return getValidatorFromAttribute(contactAttribute).isValid(
                  value
                )
              }
            ),
        },
        // noSortEdges: https://github.com/jquense/yup/issues/720
        // Without these, there are cyclical dependencies between interdependent
        // fields, and validation breaks.
        [
          ['topLevelField', 'contactAttribute'],
          ['contactAttribute', 'defaultValue'],
        ]
      )
    ),
    consentText: nope
      .string()
      .max(1000, 'Consent text must be 1000 characters or less.')
      .required('Consent text cannot be blank.'),
    confirmationMessage: nope
      .string()
      .max(1000, 'Confirmation message must be 1000 characters or less.')
      .required('Confirmation message cannot be blank.'),
    introScriptId: nope.string().notRequired(),
    iconUpload: nope.object(),
    formImageUpload: nope.object(),
  })
}

// Instead of calling the backend to get all top-level-fields per
// institution (in accordance with feature-flags, in some cases),
// hard-code the only available ones for this feature.
export const availableTopLevelFields: IContactField[] = [
  {
    field: 'name_first',
    readableName: 'First Name',
    type: ContactAttributeType.TEXT,
  },
  {
    field: 'name_last',
    readableName: 'Last Name',
    type: ContactAttributeType.TEXT,
  },
  {
    field: 'name_preferred',
    readableName: 'Preferred Name',
    type: ContactAttributeType.TEXT,
  },
  { field: 'phone', readableName: 'Phone', type: ContactAttributeType.PHONE },
  { field: 'email', readableName: 'Email', type: ContactAttributeType.EMAIL },
]

export const mapHelloPageResToFormState = (
  res: IHelloPageResponse,
  contactAttributes: IContactField[]
): IHelloPagesConfigFormData => {
  const fieldsObject = res.attribute_fields.reduce(
    (
      acc: { userFields: IField[]; hiddenFields: IField[] },
      elemRes: IHelloPageFieldResponse
    ) => {
      const topLevelField:
        | IContactField
        | undefined = availableTopLevelFields.find(
        elem => elem.field === elemRes.top_level_field
      )
      const contactAttribute:
        | IContactField
        | undefined = contactAttributes.find(
        elem => elem.id === elemRes.contact_attribute
      )
      const field: IField = {
        id: elemRes.id,
        order: elemRes.order,
        visible: elemRes.visible,
        required: elemRes.required,
        displayName: elemRes.display_field_name ?? undefined,
        topLevelField,
        contactAttribute,
        key: uuid.v4(),
        defaultValue: elemRes.default_field_value ?? undefined,
      }

      const key = elemRes.visible ? 'userFields' : 'hiddenFields'
      acc[key].push(field)
      return acc
    },
    { userFields: [], hiddenFields: [] }
  )

  return {
    title: res.page_title,
    urlPath: res.url_path,
    published: res.published,
    formHeader: res.form_header ?? undefined,
    formDescription: res.form_description ?? undefined,
    userFields: fieldsObject.userFields.filter(notUndefined),
    hiddenFields: fieldsObject.hiddenFields.filter(notUndefined),
    consentText: res.consent_text,
    confirmationMessage: res.confirmation_message,
    introScriptId: res.dialog_id ?? undefined,
    iconUpload: !!res.icon
      ? {
          id: res.icon.id,
          collegeId: res.icon.college_id,
          bucket: res.icon.bucket,
          key: res.icon.key,
          completed: res.icon.completed,
          contentType: res.icon.content_type,
          href: res.icon.href,
          name: res.icon.name,
          public: res.icon.public,
          size: res.icon.size,
        }
      : undefined,
    formImageUpload: !!res.form_image
      ? {
          id: res.form_image.id,
          collegeId: res.form_image.college_id,
          bucket: res.form_image.bucket,
          key: res.form_image.key,
          completed: res.form_image.completed,
          contentType: res.form_image.content_type,
          href: res.form_image.href,
          name: res.form_image.name,
          public: res.form_image.public,
          size: res.form_image.size,
        }
      : undefined,
  }
}

export const mapHelloPageFormStateToReq = (
  formState: IHelloPagesConfigFormData
) => ({
  page_title: formState.title,
  url_path: formState.urlPath,
  published: formState.published,
  form_header: formState.formHeader,
  form_description: formState.formDescription,
  attribute_fields: formState.userFields
    .concat(formState.hiddenFields)
    .map(field => ({
      id: field.id,
      order: field.order,
      visible: field.visible,
      required: field.required,
      display_field_name: field.displayName,
      top_level_field: field.topLevelField?.field,
      contact_attribute: field.contactAttribute?.id,
      default_field_value: field.defaultValue,
    })),
  confirmation_message: formState.confirmationMessage,
  consent_text: formState.consentText,
  icon_id: formState.iconUpload?.id,
  form_image_id: formState.formImageUpload?.id,
  dialog_id: formState.introScriptId,
})

// These will always be present in the User Fields section
// and should be automatically checked as required.
export const REQUIRED_TOP_LEVEL_FIELDS: IHardCodedField[] = [
  {
    displayName: 'First Name',
    topLevelField: {
      field: 'name_first',
      readableName: 'First Name',
      type: ContactAttributeType.TEXT,
    },
    contactAttribute: undefined,
    order: 0,
    visible: true,
    key: uuid.v4(),
    required: true,
  },
  {
    displayName: 'Last Name',
    topLevelField: {
      field: 'name_last',
      readableName: 'Last Name',
      type: ContactAttributeType.TEXT,
    },
    contactAttribute: undefined,
    order: 1,
    visible: true,
    key: uuid.v4(),
    required: true,
  },
  {
    displayName: 'Phone',
    topLevelField: {
      field: 'phone',
      readableName: 'Phone',
      type: ContactAttributeType.PHONE,
    },
    contactAttribute: undefined,
    order: 2,
    visible: true,
    key: uuid.v4(),
    required: true,
  },
]

export const OPTIONAL_TOP_LEVEL_FIELDS: IHardCodedField[] = [
  {
    displayName: 'Preferred Name',
    topLevelField: {
      field: 'name_preferred',
      readableName: 'Preferred Name',
      type: ContactAttributeType.TEXT,
    },
    contactAttribute: undefined,
    order: 3,
    visible: true,
    key: uuid.v4(),
  },
  {
    displayName: 'Email',
    topLevelField: {
      field: 'email',
      readableName: 'Email',
      type: ContactAttributeType.EMAIL,
    },
    contactAttribute: undefined,
    order: 4,
    visible: true,
    key: uuid.v4(),
  },
]

export const HELLO_PAGE_BASE_URL = 'https://hello.mainstay.com/'
export const DEFAULT_CONFIRMATION_MESSAGE =
  'The form was successfully submitted.'

export const initialConfigData = {
  title: undefined,
  urlPath: undefined,
  published: false,
  formHeader: undefined,
  formDescription: undefined,
  userFields: REQUIRED_TOP_LEVEL_FIELDS,
  hiddenFields: [],
  consentText: undefined,
  confirmationMessage: DEFAULT_CONFIRMATION_MESSAGE,
  introScriptId: undefined,
  iconUpload: undefined,
  formImageUpload: undefined,
}
