import * as React from 'react'
import { VerticalNode } from 'components/NodeSubtree/NodeSubtree'
import ExitActionConditionalRenderer from 'components/ExitActionConditionalRenderer/ExitActionConditionalRenderer'
import { DEFAULT_ADDITIONAL_PAUSE_TIME } from 'const/settings'
import ConnectedSubtree from 'components/ConnectedSubtree/ConnectedSubtree'
import {
  ICampaignScriptStep,
  PromptType,
  IExitAction,
  ExitActionType,
} from 'store/campaign-scripts/reducer'
import { PauseTimeNode } from 'components/EditableOutgoingNode/PauseTimeNode'

export const NodeChildren = ({
  workflowStep,
  newExitAction,
  editable,
  campaignReview,
}: {
  workflowStep: ICampaignScriptStep
  newExitAction: IExitAction | null
  editable?: boolean
  campaignReview?: boolean
}) => {
  const isknownExitAction = React.useCallback(
    (action: IExitAction) =>
      Object.values(ExitActionType).includes(action.name),
    []
  )
  const showPauseTimeNode = React.useMemo(
    () =>
      (workflowStep.pauseTime && workflowStep.pauseTime !== 0) ||
      workflowStep.showPauseTimeForm,
    [workflowStep.pauseTime, workflowStep.showPauseTimeForm]
  )

  if (!workflowStep) {
    return null
  }

  const exitActions = [...(workflowStep.exitActions || [])].concat(
    newExitAction ? [newExitAction] : []
  )

  return (
    <>
      {showPauseTimeNode && (
        <VerticalNode>
          <PauseTimeNode
            startInEditMode={!!workflowStep.showPauseTimeForm}
            pauseTimeSeconds={
              workflowStep.pauseTime || DEFAULT_ADDITIONAL_PAUSE_TIME
            }
            dialogState={workflowStep}
            editable={editable}
          />
        </VerticalNode>
      )}
      {exitActions.filter(isknownExitAction).map((x, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${x.name}${i}`} className="d-flex flex-row">
          <ExitActionConditionalRenderer
            exitAction={x}
            editable={!!editable}
            step={workflowStep}
            actionIndex={i}
            campaignReview={campaignReview}
          />
        </div>
      ))}
    </>
  )
}

export const getNodeBranches = (
  workflowStep: ICampaignScriptStep,
  editable: boolean = false,
  campaignReview: boolean = false
): React.ReactNodeArray => {
  if (!workflowStep) {
    return []
  }
  switch (workflowStep.promptType) {
    case PromptType.open:
    case PromptType.auto:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (typeof workflowStep.nextStates.default!.default === 'string') {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        return [
          <ConnectedSubtree
            key={workflowStep.id + '__default'}
            branchName="default"
            editable={editable}
            workflowStepId={workflowStep.nextStates.default!.default}
            parentWorkflowStepId={workflowStep.id}
            campaignReview={campaignReview}
          />,
        ]
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    case PromptType.boolean:
      const trueState =
        workflowStep.nextStates.default &&
        workflowStep.nextStates.default.default
          ? workflowStep.nextStates.default
          : workflowStep.nextStates.true
      const falseState = workflowStep.nextStates.false
      const nodeArray = []
      if (trueState && typeof trueState.default === 'string') {
        nodeArray.push(
          <ConnectedSubtree
            key={workflowStep.id + '__true'}
            branchName="true"
            editable={editable}
            workflowStepId={trueState.default}
            parentWorkflowStepId={workflowStep.id}
            campaignReview={campaignReview}
          />
        )
      } else {
        // We need an empty node here just to get the nodes to render
        // in the correct order
        nodeArray.push(<></>)
      }
      if (falseState && typeof falseState.default === 'string') {
        nodeArray.push(
          <ConnectedSubtree
            key={workflowStep.id + '__false'}
            branchName="false"
            editable={editable}
            workflowStepId={falseState.default}
            parentWorkflowStepId={workflowStep.id}
            campaignReview={campaignReview}
          />
        )
      }
      return nodeArray
    case PromptType.number:
      const options = workflowStep.multipleChoices?.map(x => x.prompt) ?? []
      return options.map((_x, i) => {
        const key = i + 1
        const nextState =
          workflowStep.nextStates[key] || workflowStep.nextStates.default
        if (nextState != null && typeof nextState.default === 'string') {
          return (
            <ConnectedSubtree
              key={key}
              editable={editable}
              parentWorkflowStepId={workflowStep.id}
              branchName={key}
              workflowStepId={nextState.default}
              campaignReview={campaignReview}
            />
          )
        } else {
          // We have to return this here otherwise the number branches wont render
          // in the correct order
          return <></>
        }
      })
  }
}
