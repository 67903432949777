import * as React from 'react'
import classnames from 'classnames'
import { IModalState, RoundedCircle } from 'page/calendar/Calendar'
import Tooltip from 'components/Tooltip/Tooltip'
import { Link } from 'util/routing'
import Time from 'components/TimeLocale/TimeLocale'
import { formatImportSegmentLabel } from 'util/importSegmentLabels'
import { AHIcon, AHIconType } from 'components/Icons/AHIcon/AHIcon'
import {
  CONTACTS,
  CAMPAIGN_SCHEDULER_ROUTES,
  CAMPAIGN_HISTORY,
} from 'const/routes'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { RefreshIcon } from 'components/Icons/Refresh/Refresh'
import 'components/CalendarPopover/CalendarPopover.scss'
import { Button } from 'components/Button/Button'
import { Radio } from 'components/MaterialComponents/MaterialComponents'
import moment from 'moment-timezone'
import { DeleteRecurringCampaignTypeEnum } from 'store/scheduledCampaigns/reducer'
import { generatePath } from 'react-router'

const ImportReportRecipient = ({
  displayString,
  importReportId,
  showLink,
  className,
}: {
  displayString: string
  importReportId: string | null
  showLink: boolean
  className?: string
}) =>
  importReportId && showLink ? (
    <PermissionsGuard permission={PERMISSIONS.IMPORT.VIEW}>
      <Link
        className={className}
        to={`${CONTACTS.IMPORT_REPORT_LIST}${importReportId}`}>
        <div>{displayString}</div>
        <AHIcon name="open_in_new" className="ml-1" />
      </Link>
    </PermissionsGuard>
  ) : (
    <span className={className}>{displayString}</span>
  )

export class RecipientGroup extends React.PureComponent<{
  importReportId: string | null
  recipientLabel: string | null
  filterName: string | null
  showLink: boolean
  className?: string
  contactFilter?: number | undefined
}> {
  render() {
    const {
      filterName,
      importReportId,
      recipientLabel,
      showLink,
      className,
      contactFilter,
    } = this.props

    // Has recipient group
    if (recipientLabel) {
      const labels = recipientLabel.split(/\s*,\s+/)
      const displayString = labels.map(formatImportSegmentLabel).join(', ')
      return (
        <ImportReportRecipient
          importReportId={importReportId}
          displayString={displayString}
          showLink={showLink}
          className={className}
        />
      )
      // Has filter with a name
    } else if (filterName) {
      return (
        <PermissionsGuard permission={PERMISSIONS.AUDIENCE.VIEW}>
          <Link
            className={className}
            to={`${CONTACTS.AUDIENCES}${contactFilter}`}
            target="_blank">
            <div>
              {filterName} <AHIcon name="open_in_new" className="ml-1" />
            </div>
          </Link>
        </PermissionsGuard>
      )
      // Has only filter query
    } else {
      return <span className={className}>None</span>
    }
  }
}

interface ICalendarPopoverRowProps {
  icon: AHIconType
  children: React.ReactNode
}

const CalendarPopoverRow = ({ icon, children }: ICalendarPopoverRowProps) => {
  return (
    <div className="mb-2 d-flex align-items-start">
      <AHIcon name={icon} className="mr-2 text-muted" />
      {children}
    </div>
  )
}

const LockedEventIcon = ({ message }: { message: string }) => {
  return (
    <Tooltip content={message}>
      <div>
        <AHIcon name="lock" className="text-muted" />
      </div>
    </Tooltip>
  )
}

interface ICalendarPopoverContainerProps {
  modal: IModalState
  handleDelete: (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => void
  regionFilteringEnabled: boolean
}

enum CampaignPopoverContent {
  deleteRecurringCampaign = 'deleteRecurringCampaign',
  default = 'default',
}

export function CalendarPopoverContainer({
  modal,
  handleDelete,
  regionFilteringEnabled,
}: ICalendarPopoverContainerProps) {
  const [campaignPopoverContent, setCampaignPopoverContent] = React.useState<
    CampaignPopoverContent
  >(CampaignPopoverContent.default)

  const deletableCampaign = React.useMemo(() => {
    if (modal.recurring) {
      return moment.utc().isBefore(moment(modal.occurrenceDate).utc())
    }
    return !modal.started
  }, [modal.started, modal.occurrenceDate, modal.recurring])

  return (
    <div className="p-3 shadow border rounded bg-white calendar-popover-container">
      <div className="d-flex" />
      <div className="d-flex justify-content-end mb-2">
        {!deletableCampaign ? (
          <LockedEventIcon message="Past campaigns cannot be removed." />
        ) : !modal.hasPermissionToDelete ? (
          <LockedEventIcon message="You don't have permission to edit events for this region." />
        ) : (
          <>
            <PermissionsGuard
              className="w-32px text-center"
              permission={PERMISSIONS.CAMPAIGN.DELETE}>
              <AHIcon
                name="delete"
                className={classnames(
                  'pointer large-icon text-mainstay-almost-black-30',
                  {
                    'not-allowed': modal.deleting,
                  }
                )}
                onClick={() => {
                  if (modal.recurring) {
                    return setCampaignPopoverContent(
                      CampaignPopoverContent.deleteRecurringCampaign
                    )
                  }
                  handleDelete()
                }}
              />
            </PermissionsGuard>
            {!modal.started && (
              <PermissionsGuard permission={PERMISSIONS.CAMPAIGN.EDIT}>
                <Link
                  className="text-decoration-none"
                  to={`${CAMPAIGN_SCHEDULER_ROUTES.EDIT_INDEX}${modal.id}${CAMPAIGN_SCHEDULER_ROUTES.TRIGGER_CONFIG}`}>
                  <AHIcon
                    name="edit"
                    className={classnames(
                      'pointer large-icon text-mainstay-almost-black-30 text-decoration-none',
                      {
                        'not-allowed': modal.deleting,
                      }
                    )}
                  />
                </Link>
              </PermissionsGuard>
            )}
          </>
        )}
      </div>
      {regionFilteringEnabled && modal.regionDisplayName && (
        <div className="d-flex mb-2">
          <div className="w-32px mr-2 d-flex align-items-center justify-content-center">
            <RoundedCircle size={15} color={modal.color} />
          </div>
          <div className="text-muted">{modal.regionDisplayName}</div>
        </div>
      )}
      <CalendarPopoverContent
        modal={modal}
        campaignPopoverContent={campaignPopoverContent}
        setCampaignPopoverContent={setCampaignPopoverContent}
        handleDelete={handleDelete}
      />
    </div>
  )
}

interface ICalendarPopoverContentProps {
  modal: IModalState
  campaignPopoverContent: CampaignPopoverContent
  setCampaignPopoverContent: (
    campaignPopoverContent: CampaignPopoverContent
  ) => void
  handleDelete: (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => void
}

function CalendarPopoverContent({
  modal,
  campaignPopoverContent,
  setCampaignPopoverContent,
  handleDelete,
}: ICalendarPopoverContentProps) {
  if (
    campaignPopoverContent === CampaignPopoverContent.deleteRecurringCampaign
  ) {
    return (
      <DeleteRecurringCampaignPopoverContent
        setCampaignPopoverContent={setCampaignPopoverContent}
        handleDelete={handleDelete}
        occurrenceDate={modal.occurrenceDate}
      />
    )
  }

  const campaignDate = modal.occurrenceDate?.toISOString()
  const recurringCampaignID =
    modal.completedRecurringCampaigns[
      campaignDate ? campaignDate.substring(0, 10) : ''
    ]

  return (
    <div className="d-flex flex-column px-4">
      <div className="d-flex">
        {!moment.utc().isBefore(moment(modal.occurrenceDate).utc()) ||
        modal.started ? (
          <PermissionsGuard permission={PERMISSIONS.CAMPAIGN.VIEW}>
            <Link
              to={
                recurringCampaignID
                  ? generatePath(CAMPAIGN_HISTORY.RECURRING_DETAIL, {
                      id: recurringCampaignID,
                    })
                  : generatePath(CAMPAIGN_HISTORY.DETAIL, { id: modal.id })
              }
              target="_blank"
              className="text-decoration-none">
              <div className="d-inline-flex">
                <h4 className="">{modal.title}</h4>
                <AHIcon name="open_in_new" className="ml-1 pt-1" />
              </div>
            </Link>
          </PermissionsGuard>
        ) : (
          <h4>{modal.title}</h4>
        )}
        {modal.recurring && (
          <RefreshIcon className="ml-1 justify-content-right" />
        )}
      </div>
      <p className="text-mainstay-dark-blue-65 mb-2">{modal.description}</p>

      {/*
        The icons in the following divs CalendarPopoverRow have
        ~6px of white pixels within their svgs. In order to get the text above
        and the icons below perfectly vertically aligned, we need to do this unholy bologna below.
      */}
      <div
        className="mt-1"
        style={{
          marginLeft: '-7px',
        }}>
        <CalendarPopoverRow icon="send">
          <Time dateTime={modal.occurrenceDate || modal.on} />{' '}
        </CalendarPopoverRow>
        <CalendarPopoverRow icon="people">
          <RecipientGroup
            importReportId={modal.importReportId}
            recipientLabel={modal.recipientLabel}
            filterName={modal.filterName}
            showLink={true}
            contactFilter={modal.contactFilter}
            className="d-inline-flex text-decoration-none"
          />
        </CalendarPopoverRow>
        <CalendarPopoverRow icon="comment">
          <PermissionsGuard permission={PERMISSIONS.SCRIPT.VIEW}>
            <Link
              to={`/campaign-scripts/${modal.campaign}/view`}
              target="_blank"
              className="d-inline-flex text-decoration-none">
              <div>{modal.workflowHumanName}</div>
              <AHIcon name="open_in_new" className="ml-1" />
            </Link>
          </PermissionsGuard>
        </CalendarPopoverRow>
      </div>
    </div>
  )
}

interface IDeleteCampaignPopoverContentProps {
  setCampaignPopoverContent: (
    campaignPopoverContent: CampaignPopoverContent
  ) => void
  occurrenceDate?: Date
  handleDelete: (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => void
}

function DeleteRecurringCampaignPopoverContent({
  handleDelete,
  setCampaignPopoverContent,
  occurrenceDate,
}: IDeleteCampaignPopoverContentProps) {
  const [deleteType, setDeleteType] = React.useState<
    DeleteRecurringCampaignTypeEnum
  >()
  return (
    <div className="d-flex">
      <div className="w-32px mr-2" />
      <div className="d-flex row gap-3">
        <h5 className="mb-0">Delete recurring event?</h5>
        <div>
          <label className="d-flex align-items-center mb-0 pointer">
            <Radio
              color="primary"
              className="py-2 pl-0"
              value={DeleteRecurringCampaignTypeEnum.futureOccurrences}
              checked={
                deleteType === DeleteRecurringCampaignTypeEnum.futureOccurrences
              }
              onClick={() =>
                setDeleteType(DeleteRecurringCampaignTypeEnum.futureOccurrences)
              }
            />
            Delete this and all future occurrences
          </label>
          <label className="d-flex align-items-center mb-0 pointer">
            <Radio
              color="primary"
              className="py-2 pl-0"
              value={DeleteRecurringCampaignTypeEnum.occurrence}
              checked={
                deleteType === DeleteRecurringCampaignTypeEnum.occurrence
              }
              onClick={() =>
                setDeleteType(DeleteRecurringCampaignTypeEnum.occurrence)
              }
            />
            Delete only this occurrence
          </label>
        </div>
        <div className="d-flex">
          <Button
            disabled={!deleteType}
            className="mr-3"
            onClick={() =>
              deleteType && handleDelete(deleteType, occurrenceDate)
            }
            color="danger">
            Delete
          </Button>
          <Button
            onClick={() =>
              setCampaignPopoverContent(CampaignPopoverContent.default)
            }
            className="border-1px">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
