import { useState, useCallback, useMemo } from 'react'
import { SettingsPageContainer } from 'page/SettingsGeneral'
import {
  getHelloPages,
  updateHelloPage,
  deleteHelloPage,
  duplicateHelloPage,
} from 'api'
import { isLeft, isRight } from 'fp-ts/lib/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { CreateButton, PageHeader } from 'components/PageHeader/PageHeader'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { Link } from 'util/routing'
import { HELLO_PAGES, CAMPAIGN_HISTORY } from 'const/routes'
import { generatePath, useHistory } from 'react-router'
import strftime from 'strftime'
import { useSelector } from 'util/hooks'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import { OnOffToggleSwitch } from 'components/LabeledToggle/LabeledToggle'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import 'page/hello-pages/SettingsHelloPages.scss'
import {
  IAsyncRows,
  IAsyncTableOptions,
  SortTableAsync,
} from 'components/TableAsyncSort/TableAsyncSort'
import { isEqual } from 'lodash'
import { HELLO_PAGE_BASE_URL } from 'page/hello-pages/utils'
import { ConfirmationModal } from 'components/Modal/Modal'

interface IHelloPageListItem {
  id: number
  page_title: string
  url_path: string
  optins?: number
  started: boolean
  modified: string
  dialog_id: string | null
  created_by: string
  published: boolean
}

const DEFAULT_PAGE_SIZE = 30

function HelloPagesPublishedToggle({
  helloPage,
}: {
  helloPage: IHelloPageListItem
}) {
  const [isPublished, setIsPublished] = useState(helloPage.published)
  const updateStatus = useCallback(async (id: number, data: object) => {
    try {
      const res = await updateHelloPage({ id, data })
      if (isRight(res)) {
        setIsPublished(res.right.published)
      }
      if (isLeft(res)) {
        toast.error('Could not update hello page status')
      }
    } catch (error) {
      toast.error('Could not update hello page status')
    }
  }, [])

  return (
    <MainstayFlexTableCol xs={1}>
      <OnOffToggleSwitch
        onChange={() => updateStatus(helloPage.id, { published: !isPublished })}
        checked={isPublished}
        size="sm"
      />
    </MainstayFlexTableCol>
  )
}

function HelloPagesActionMenu({
  helloPage,
  deleteHelloPageFromList,
  duplicateHelloPage,
  generateEditPath,
}: {
  helloPage: IHelloPageListItem
  deleteHelloPageFromList: (id: number) => void
  duplicateHelloPage: (id: number) => void
  generateEditPath: (id: number) => string
}) {
  const history = useHistory()
  const eventLocation = 'hello pages'
  const editPath = generateEditPath(helloPage.id)

  const menuItems = [
    {
      label: 'Edit',
      icon: <AHIcon name="edit" />,
      onSelect: () => history.push(editPath),
      eventObject: 'edit hello page',
    },
    {
      label: 'Delete',
      icon: <AHIcon name="delete" />,
      onSelect: () => deleteHelloPageFromList(helloPage.id),
      eventObject: 'delete hello page',
    },
    {
      label: 'Duplicate',
      icon: <AHIcon name="copy" />,
      onSelect: () => duplicateHelloPage(helloPage.id),
      eventObject: 'duplicate hello page',
    },
  ]

  if (helloPage.dialog_id && helloPage.started) {
    const detailPagePath = generatePath(CAMPAIGN_HISTORY.HELLO_PAGE_DETAIL, {
      id: helloPage.id,
      dialogId: helloPage.dialog_id,
    })
    menuItems.push({
      label: 'View Report',
      icon: <AHIcon name="campaign" />,
      onSelect: () => history.push(detailPagePath),
      eventObject: 'view hello page report',
    })
  }

  return (
    <ActionMenu
      key={helloPage.id}
      popoverPlacement="bottom-end"
      className="mt-2"
      eventLocation={eventLocation}
      eventAction="click"
      eventObject="overflow menu"
      menuItems={menuItems}
    />
  )
}

export const generateHelloPageLink = (path: string) => {
  const base = HELLO_PAGE_BASE_URL
  return base + path
}

export default function SettingsHelloPagesList() {
  const [helloPages, setHelloPages] = useState<IHelloPageListItem[]>([])
  const [totalResults, setTotalResults] = useState(0)

  const [pagePendingDelete, setPagePendingDelete] = useState<
    IHelloPageListItem | undefined
  >(undefined)

  const [loading, setLoading] = useState(true)

  const history = useHistory()
  const dateFormat = useSelector(getInstitutionDateFormat)

  const eventAction = 'click'
  const eventLocation = 'hello pages'

  const getMainstayPath = useCallback((id: number) => {
    return generatePath(HELLO_PAGES.DETAIL, { id })
  }, [])

  const fetchHelloPages = useCallback(
    async (
      { page = 1, sortBy, order }: IAsyncTableOptions,
      pageSize: number = DEFAULT_PAGE_SIZE
    ) => {
      try {
        setLoading(true)
        const res = await getHelloPages({ page, pageSize, sortBy, order })
        if (isRight(res)) {
          if (!isEqual(helloPages, res.right.results)) {
            setHelloPages(res.right.results)
            setTotalResults(res.right.count)
          }
        }
        if (isLeft(res)) {
          toast.error('Could not fetch hello pages')
        }
      } catch (error) {
        toast.error('Could not fetch hello pages')
      } finally {
        setLoading(false)
      }
    },
    [helloPages]
  )

  const resetDeletion = () => {
    setPagePendingDelete(undefined)
  }

  const deleteHelloPageFromList = useCallback(
    async (id: number) => {
      try {
        const res = await deleteHelloPage({ id })
        if (isRight(res)) {
          toast.success('Hello page deleted')
          fetchHelloPages(
            { page: 1, sortBy: 'name', order: 'asc' },
            DEFAULT_PAGE_SIZE
          )
        }
        if (isLeft(res)) {
          toast.error('Could not delete hello page')
        }
      } catch (error) {
        toast.error('Could not delete hello page')
      }
    },
    [fetchHelloPages]
  )

  const handleDuplicateHelloPage = useCallback(
    async (id: number) => {
      try {
        const res = await duplicateHelloPage({ id })
        if (isRight(res)) {
          toast.success('Hello page duplicated')
          fetchHelloPages(
            { page: 1, sortBy: 'name', order: 'asc' },
            DEFAULT_PAGE_SIZE
          )
        }
        if (isLeft(res)) {
          toast.error('Could not duplicate hello page')
        }
      } catch (error) {
        toast.error('Could not duplicate hello page')
      }
    },
    [fetchHelloPages]
  )

  const memoizedRows = useMemo<IAsyncRows>(() => {
    if (!helloPages) {
      return { total: 0, rows: [], error: true }
    } else {
      const rowsFromRes = helloPages.map(helloPage => {
        const modifiedDateCell = helloPage.modified ? (
          strftime(dateFormat, new Date(helloPage?.modified))
        ) : (
          <></>
        )

        const titleCell = (
          <MainstayFlexTableCol xs={3}>
            <Link to={getMainstayPath(helloPage.id)}>
              {helloPage.page_title}
            </Link>
          </MainstayFlexTableCol>
        )

        const urlPathCell = (
          <MainstayFlexTableCol xs={4}>
            <a
              target="_blank"
              className="list-url-path"
              href={generateHelloPageLink(helloPage.url_path)}>
              {generateHelloPageLink(helloPage.url_path)}
            </a>
          </MainstayFlexTableCol>
        )

        return [
          titleCell,
          urlPathCell,
          helloPage.optins ? String(helloPage.optins) : '0',
          modifiedDateCell,
          <HelloPagesPublishedToggle
            key={helloPage.id}
            helloPage={helloPage}
          />,
          <HelloPagesActionMenu
            key={helloPage.id}
            helloPage={helloPage}
            generateEditPath={getMainstayPath}
            deleteHelloPageFromList={() => {
              setPagePendingDelete(helloPage)
            }}
            duplicateHelloPage={handleDuplicateHelloPage}
          />,
        ]
      })

      return {
        total: totalResults,
        rows: rowsFromRes,
      }
    }
  }, [
    helloPages,
    dateFormat,
    getMainstayPath,
    handleDuplicateHelloPage,
    totalResults,
  ])

  return (
    <SettingsPageContainer>
      <>
        <PageHeader
          title="Hello Pages"
          description="Learners can opt in to text messaging by visiting these landing pages.">
          <CreateButton
            title="New Hello Page"
            onClick={() => history.push(HELLO_PAGES.CREATE)}
          />
        </PageHeader>

        <SortTableAsync
          itemsName="hello pages"
          tablePrefix="hello-pages"
          fetchRows={fetchHelloPages}
          renderRows={memoizedRows}
          pageSize={DEFAULT_PAGE_SIZE}
          showLoader={loading}
          pagerEventObject="of hello pages"
          eventAction={eventAction}
          eventLocation={eventLocation}
          columns={[
            {
              title: 'Name',
              xs: 3,
              sortByField: 'name',
              alignment: 'start',
              eventAction,
              eventLocation,
              eventTrackerName: 'hello pages name',
            },
            {
              title: 'URL',
              xs: 4,
              alignment: 'start',
              sortByField: 'url',
              eventAction,
              eventLocation,
              eventTrackerName: 'hello pages url',
            },
            {
              title: 'Opt-ins',
              xs: 1,
              alignment: 'start',
              sortByField: 'optins',
              eventAction,
              eventLocation,
              eventTrackerName: 'hello pages opt-ins',
            },
            {
              title: 'Last Modified',
              xs: 2,
              alignment: 'start',
              sortByField: 'date',
              eventAction,
              eventLocation,
              eventTrackerName: 'hello pages last modified',
            },
            {
              title: 'Status',
              xs: 1,
              alignment: 'start',
              sortByField: 'status',
              eventAction,
              eventLocation,
              eventTrackerName: 'hello pages status',
            },
            {
              title: '  ',
              xs: 1,
              alignment: 'end',
            },
          ]}
        />
        {!!pagePendingDelete && (
          <ConfirmationModal
            title={`Delete ${pagePendingDelete.page_title}?`}
            confirmButtonText="Delete Hello Page"
            cancelButtonText="Cancel"
            onConfirm={() => {
              deleteHelloPageFromList(pagePendingDelete.id)
              resetDeletion()
            }}
            onCancel={() => {
              resetDeletion()
            }}
            helpText="Deleting this Hello Page will remove it from your settings, and it will no longer be accessible at its URL Path."
            show={!!pagePendingDelete}
            onClose={() => {
              resetDeletion()
            }}
            hideCheckbox={true}
            eventAction={eventAction}
            eventLocation={eventLocation}
            eventObject="delete"
          />
        )}
      </>
    </SettingsPageContainer>
  )
}
