import { useState, useEffect } from 'react'
import { SettingsPageContainer } from 'page/SettingsGeneral'
import { IntegrationPanel } from 'components/IntegrationPanel/IntegrationPanel'
import { FocusedInputShape, isInclusivelyBeforeDay } from 'react-dates'
import { History } from 'history'
import { timeout } from 'util/timeout'
import { isRight } from 'fp-ts/lib/Either'
import * as Raven from '@sentry/browser'
import {
  WebData,
  Success,
  Failure,
  isLoading,
  isSuccessOrRefetching,
  Loading,
  isSuccess,
} from 'store/webdata'
import { AxiosResponse } from 'axios'
import { SearchInput } from 'components/SearchInput/SearchInput'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'
import * as api from 'api'
import { IIntegrationError, IIntegrationErrorsResponse } from 'api/response'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import {
  MainstayFlexTableHeaderCol,
  MainstayFlexTableCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { appendQueryFilter, getQueryFilters } from 'util/queryFilters'
import { useDebounce } from 'util/hooks'
import { Pager } from 'components/LinkPager/LinkPager'
import { solutionTitleMapping } from 'page/SettingsBrowseIntegrations'
import { CONTACTS } from 'const/routes'
import { Link } from 'util/routing'
import moment from 'moment-timezone'
import { DateRangeModal } from 'components/ReportDownloadInterface/DateRangeModal'
import { openDownloadURL } from 'util/links'

export const IntegrationError = ({
  created,
  error_code,
  error_message,
  platform,
  direction,
  admithub_contact_id,
  platform_contact_id,
}: Omit<IIntegrationError, 'id' | 'solution_instance'>) => {
  return (
    <MainstayFlexTableRow>
      <MainstayFlexTableCol xs={2}>
        {new Date(created).toLocaleString()}
      </MainstayFlexTableCol>
      <MainstayFlexTableCol xs={1}>
        {platform ? solutionTitleMapping[platform]?.name : platform ?? ''}
      </MainstayFlexTableCol>
      <MainstayFlexTableCol xs={1}>{direction}</MainstayFlexTableCol>
      {admithub_contact_id ? (
        <Link
          target="blank"
          className="pointer text-decoration-none col-2"
          to={`${CONTACTS.INDEX}${admithub_contact_id}`}>
          <div>
            <MainstayFlexTableCol xs={2}>
              {admithub_contact_id}
            </MainstayFlexTableCol>
          </div>
        </Link>
      ) : (
        <div className="col-2" />
      )}
      <MainstayFlexTableCol className="text-truncate" xs={2}>
        {platform_contact_id}
      </MainstayFlexTableCol>
      <MainstayFlexTableCol className="text-truncate" xs={2}>
        {error_code}
      </MainstayFlexTableCol>
      <MainstayFlexTableCol xs={2}>{error_message}</MainstayFlexTableCol>
    </MainstayFlexTableRow>
  )
}

interface IIntegrationErrorsTableProps {
  integrationErrors: WebData<Array<IIntegrationError>>
}

export const IntegrationErrorsTable = ({
  integrationErrors,
}: IIntegrationErrorsTableProps) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden">
      <MainstayFlexTable>
        <RefetchingOverlay enabled={isLoading(integrationErrors)}>
          <MainstayFlexTableHeader>
            <MainstayFlexTableHeaderCol xs={2}>
              Log Timestamp
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={1}>
              Integration
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={1}>
              Direction
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={2}>
              Mainstay Contact ID
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={2}>
              CRM ID
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={2}>
              Error Code
            </MainstayFlexTableHeaderCol>
            <MainstayFlexTableHeaderCol xs={2}>
              Error Message
            </MainstayFlexTableHeaderCol>
          </MainstayFlexTableHeader>
          {isSuccessOrRefetching(integrationErrors) &&
            integrationErrors.data.length > 0 &&
            integrationErrors.data.map((elem: IIntegrationError) => (
              <IntegrationError
                key={elem.id}
                admithub_contact_id={elem.admithub_contact_id ?? ''}
                platform_contact_id={elem.platform_contact_id ?? ''}
                platform={elem.platform ?? ''}
                direction={elem.direction ?? ''}
                created={elem.created}
                error_code={elem.error_code}
                error_message={elem.error_message ?? ''}
              />
            ))}
        </RefetchingOverlay>
      </MainstayFlexTable>
    </div>
  )
}

interface IIntegrationsErrorsPageProps {
  history: History
}

export function IntegrationsErrorsPage({
  history,
}: IIntegrationsErrorsPageProps) {
  const [errors, setErrors] = useState<WebData<Array<IIntegrationError>>>()
  const [search, setSearch] = useState('')
  const [errorsCount, setErrorsCount] = useState<WebData<number>>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
  })
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(
    'startDate'
  )
  const [downloading, setDownloading] = useState<boolean>(false)

  const pageNumber = parseInt(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (getQueryFilters(window.location)['page'] as string) || '1',
    10
  )

  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    setErrors(Loading())
    setErrorsCount(Loading())
    api
      .fetchIntegrationErrors(pageNumber)
      .then((res: AxiosResponse<IIntegrationErrorsResponse>) => {
        setErrors(Success(res.data.results))
        setErrorsCount(Success(res.data.count))
      })
      .catch(() => {
        setErrors(Failure(undefined))
        setErrorsCount(Failure(0))
      })
  }, [pageNumber])

  useEffect(() => {
    setErrors(Loading())
    setErrorsCount(Loading())
    const search = debouncedSearch
    api
      .fetchIntegrationErrors(pageNumber, search)
      .then((res: AxiosResponse<IIntegrationErrorsResponse>) => {
        setErrors(Success(res.data.results))
        setErrorsCount(Success(res.data.count))
      })
      .catch(() => {
        setErrors(Failure(undefined))
        setErrorsCount(Failure(0))
      })
  }, [debouncedSearch, pageNumber])

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    history.replace(appendQueryFilter(window.location, 'page', '1'))
  }

  const handleClickDownload = (_e: React.MouseEvent<HTMLElement>) => {
    setShowModal(true)
  }

  const somethingWentWrongMsg =
    'Something went wrong downloading your Sync Logs'

  const pollDownloadIntegrationErrors = async (
    taskId: string,
    ms: number = 2000
  ) => {
    try {
      const res = await api.pollDownloadIntegrationErrors(taskId)
      if (isRight(res)) {
        if (res.right.task_status === 'SUCCESS') {
          setDownloading(false)
          const type = res.right.result === 'ok' ? 'success' : 'error'
          toast(res.right.detail, { type })
          if (res.right.data.url) {
            openDownloadURL({
              url: res.right.data.url,
            })
          } else {
            throw Error(
              'Error downloading sync logs: response shape correct, but URL is NULL'
            )
          }
          return
        }
        await timeout(ms)
        await pollDownloadIntegrationErrors(taskId)
      } else {
        throw Error('Error downloading sync logs: response shape incorrect')
      }
    } catch (e) {
      toast(somethingWentWrongMsg, { type: 'error' })
      setDownloading(false)
      Raven.captureException(e)
    }
  }

  const handleSubmitDateRange = async (
    search: string,
    dateRange: { startDate: moment.Moment; endDate: moment.Moment }
  ) => {
    const { startDate, endDate } = dateRange
    setDownloading(true)
    const res = await api.downloadIntegrationErrors(startDate, endDate, search)
    if (isRight(res)) {
      const taskId = res.right.task_id
      await pollDownloadIntegrationErrors(taskId)
    } else {
      setDownloading(false)
      toast(somethingWentWrongMsg, { type: 'error' })
    }
  }

  return (
    <SettingsPageContainer>
      <IntegrationPanel
        title="Sync Log"
        className="d-flex flex-column h-100"
        includeButton={true}
        loading={downloading}
        loadingText="Downloading..."
        buttonText="Download logs"
        buttonOnClick={handleClickDownload}>
        <span>
          See the{' '}
          <a
            href="https://support.mainstay.com/hc/en-us/articles/360060835271-Import-Error-Dictionary"
            target="_blank">
            Import Error Dictionary
          </a>{' '}
          for detailed guidance on addressing these issues.
        </span>
        <div className="d-flex w-100 my-3 flex-row justify-content-between">
          <SearchInput
            className="w-25 mb-2rem"
            placeholder="Search"
            value={search || ''}
            onChange={handleSearchQueryChange}
          />
        </div>{' '}
        <IntegrationErrorsTable integrationErrors={errors} />
        {isSuccess(errors) && isSuccess(errorsCount) && (
          <Pager
            className="mt-5"
            first={1}
            current={pageNumber}
            urlFormat={_x => {
              return appendQueryFilter(window.location, 'page', String(_x))
            }}
            last={Math.max(Math.ceil(errorsCount.data / 50), 1)}
          />
        )}
        <DateRangeModal
          isOpen={showModal}
          onToggle={() => setShowModal(!showModal)}
          dateRange={dateRange}
          onChangeDateRange={({ startDate, endDate }) => {
            setDateRange({
              startDate: startDate || moment().subtract(1, 'month'),
              endDate: endDate || moment(),
            })
          }}
          focusedInput={focusedInput}
          onFocusChange={focusedInput =>
            setFocusedInput(focusedInput || 'startDate')
          }
          isOutsideRange={(day: moment.Moment) => {
            return !isInclusivelyBeforeDay(day, moment())
          }}
          onClickSubmit={() => {
            setShowModal(false)
            handleSubmitDateRange(search, dateRange)
          }}
        />
      </IntegrationPanel>
    </SettingsPageContainer>
  )
}

export default IntegrationsErrorsPage
