import React, { useState, useEffect, useReducer } from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Button } from 'components/Button/Button'
import {
  KBResponseWrite,
  KBResponseRead,
  IUpdateOrCreateResponseRequest,
} from 'components/KnowledgeSeedEditPanel/KnowledgeSeedResponse'
import 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel.scss'
import { useDispatch, useSelector } from 'util/hooks'
import { uniq } from 'lodash'

import {
  removeKnowledgeSeedAsync,
  updateKnowledgeSeedApproval,
} from 'store/knowledgeSeeder/actions'
import * as api from 'api'
import classNames from 'classnames'
import {
  getType,
  ActionType,
  createStandardAction,
  createAsyncAction,
} from 'typesafe-actions'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import {
  getHasContactAttribute,
  useParseAnswer,
} from 'components/DraftEditor/draftUtils'
import format from 'date-fns/format'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { IAttributesById } from 'store/personalization/institutionAttributes/selectors'
import { isRight } from 'fp-ts/lib/Either'
import { ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import uuid from 'uuid/v4'
import { isSuccess } from 'store/webdata'
import { IDeleteButtonProps } from 'components/FloatingTrashCan/FloatingTrashCan'
import { isLeft } from 'fp-ts/lib/These'

interface ILastEditedByProps {
  name?: string | null
  date?: string | null
}
export function LastEditedBy({ name, date }: ILastEditedByProps) {
  if (name == null || date == null) {
    return <></>
  }
  const displayDate = format(new Date(date), 'MMM d, y')
  // This should look like 'Last edited by AdmitHub Staff, Jan 12, 2019'.
  return (
    <p className="text-muted caption m-0" title={displayDate}>
      Last edited by {name}, {displayDate}
    </p>
  )
}

/**
 * If date is null then the answer was not embedded. If the date is undefined then the embedding date is unknown.
 */
export function LastEmbedded({ date }: { readonly date?: string | null }) {
  let displayDate = 'Embedding status unknown'
  if (date === null) {
    displayDate = 'Not embedded'
  } else if (date !== undefined) {
    const dateObj = new Date(date)
    displayDate =
      'Last embedded ' +
      format(dateObj, 'MMM d, y') +
      ' at ' +
      format(dateObj, 'h:mm a')
  }

  return (
    <div>
      <p className="bg-mainstay-warning-300 border text-mainstay-dark-blue-80 chip mt-1 mb-1 pr-2 border-radius-lg caption">
        {displayDate}
      </p>
    </div>
  )
}

interface IAnswerProps {
  readonly lastModifiedByName?: string
  readonly lastModifiedAt?: string
  readonly approved: boolean
  readonly answer?: string
  readonly pendingAnswer?: string
  readonly onApprove?: () => void
  readonly onUnapprove?: () => void
  readonly onDelete?: () => void
  readonly onSave: (
    params: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean
  ) => void
  readonly onToggleEditor?: (show: boolean) => void
  readonly className?: string
  readonly answerId: number
  readonly contactFilter?: {
    id: number
    name: string
  }
  readonly disableApprovalMenu?: boolean
  readonly actionInProgress: boolean
  readonly editing: boolean
  readonly allowPersonalization?: boolean
  readonly dialogId: string | null
  readonly dialogName: string | null
  readonly workflowSteps: ICampaignScriptStep[]
  readonly dragHandleProps?: DraggableProvidedDragHandleProps
  readonly isDragging?: boolean
  readonly deleteButtonProps?: IDeleteButtonProps
  readonly shouldBePersonalized?: boolean
}

export function Answer({
  answerId,
  approved,
  lastModifiedByName,
  lastModifiedAt,
  answer,
  pendingAnswer,
  onApprove,
  onUnapprove,
  onDelete,
  onSave,
  onToggleEditor,
  className,
  contactFilter,
  disableApprovalMenu = false,
  actionInProgress,
  editing,
  allowPersonalization = true,
  dialogId,
  dialogName,
  workflowSteps,
  dragHandleProps,
  isDragging,
  deleteButtonProps,
  shouldBePersonalized,
}: IAnswerProps) {
  const [editingAnswer, setEditingAnswer] = useState(answer)
  const [editingAnswerId, setEditingAnswerId] = useState<undefined | number>(
    undefined
  )
  const [editingApproved, setEditingApproved] = useState(approved)
  const [selectedFilter, setSelectedFilter] = useState(contactFilter?.id)

  const mappingResults = useSelector(getAttributeByIdMapping)
  const mapping = React.useMemo(
    () =>
      isSuccess(mappingResults)
        ? mappingResults.data
        : { institution: {}, contact: {}, toplevel: {} },
    [mappingResults]
  )

  useEffect(() => {
    setEditingAnswer(answer)
  }, [answer, editing])
  useEffect(() => {
    setEditingApproved(approved)
  }, [approved, editing])
  useEffect(() => {
    setSelectedFilter(contactFilter?.id)
  }, [contactFilter?.id, editing])

  useEffect(() => {
    setEditingAnswerId(undefined)
  }, [contactFilter?.id, answer, approved])

  const {
    selectedContactAttributes: selectedContactAttributesAndFields,
  } = useParseAnswer(editingAnswer)

  function handleCancel() {
    onToggleEditor?.(false)
    setEditingAnswerId(undefined)
  }

  function handleDelete(id: number | undefined) {
    if (id) {
      setEditingAnswerId(id)
      onDelete?.()
    }
  }

  function handleSave(request: IUpdateOrCreateResponseRequest) {
    const wasPersonalized = isAnswerPersonalized(
      { contactFilter, answer },
      mapping
    )
    const isPersonalized = isAnswerPersonalized(
      {
        contactFilter: request.contactFilter
          ? { id: request.contactFilter, name: '' }
          : undefined,
        answer: request.answer,
      },
      mapping
    )
    // If the user was editing an unpersonalized answer, but then personalized
    // it, we should move it to the top of the personalized list.
    const moveToTop = !wasPersonalized && isPersonalized

    setEditingAnswerId(request.id)
    onSave(request, moveToTop)
  }

  const audience = selectedContactAttributesAndFields?.length ? (
    <>
      {selectedContactAttributesAndFields &&
        // Deduplicate all selected contact attributes and topLevelFields
        // this way, if a user includes the same attr or field more than once,
        // only one line for that audience item will show
        uniq(selectedContactAttributesAndFields).map((attr, index) => (
          <span key={attr}>
            <span>{`Contacts with information for ${attr}`}</span>
            {index !== selectedContactAttributesAndFields.length - 1 && <br />}
          </span>
        ))}
    </>
  ) : (
    undefined
  )
  if (editing && answerId === editingAnswerId) {
    return (
      <div
        className={classNames(
          { 'shadow-border': isDragging },
          { 'opacity-50': actionInProgress && answerId === editingAnswerId }
        )}>
        <KBResponseWrite
          answerId={answerId}
          dialogId={dialogId}
          dialogName={dialogName}
          initialAnswer={editingAnswer}
          approved={editingApproved}
          selectedFilter={selectedFilter}
          workflowSteps={workflowSteps}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={onDelete ? () => handleDelete(answerId) : undefined}
          lastModifiedByName={lastModifiedByName}
          lastModifiedAt={lastModifiedAt}
          hideApproval={disableApprovalMenu}
          allowPersonalization={allowPersonalization}
          dragHandleProps={dragHandleProps}
          deleteButtonProps={deleteButtonProps}
          shouldBePersonalized={shouldBePersonalized}
        />
      </div>
    )
  }
  return (
    <div
      onClick={() => {
        setEditingAnswerId(answerId)
        onToggleEditor?.(true)
      }}
      className={classNames(
        'knowledge-answer-group-show-ui-on-hover',
        'pointer',
        { 'shadow-border': isDragging },
        { 'opacity-50': actionInProgress },
        className
      )}>
      <KBResponseRead
        answer={pendingAnswer || editingAnswer}
        dialogId={dialogId}
        dialogName={dialogName}
        workflowSteps={workflowSteps}
        onUnapprove={() => {
          setEditingAnswerId(answerId)
          setEditingApproved(false)
          onUnapprove?.()
        }}
        onApprove={() => {
          setEditingAnswerId(answerId)
          setEditingApproved(true)
          onApprove?.()
        }}
        onDelete={onDelete ? () => handleDelete(answerId) : undefined}
        approved={editingApproved}
        lastModifiedByName={lastModifiedByName}
        lastModifiedAt={lastModifiedAt}
        hideApproval={disableApprovalMenu}
        allowPersonalization={allowPersonalization}
        audience={audience}
        contactFilterName={contactFilter?.name}
        dragHandleProps={dragHandleProps}
        deleteButtonProps={deleteButtonProps}
      />
    </div>
  )
}

interface IAdmitHubGlobalAnswerProps {
  readonly lastEditedAt: string
  readonly answer: string
  copyAnswer: () => void
}
function AdmitHubGlobalAnswer({
  lastEditedAt,
  answer,
  copyAnswer,
}: IAdmitHubGlobalAnswerProps) {
  return (
    <div
      onClick={copyAnswer}
      className="border rounded p-3 my-3 hover-bg-blue-grey-050">
      <div className="text-muted small mb-2">Suggested Response</div>
      <span>{answer}</span>
      <div className="d-flex align-items-center justify-content-between my-2">
        <Button
          color="link"
          className="p-0 d-flex align-items-center text-decoration-none">
          <AHIcon name="add_circle_outline" />
          <span className="mt-1">Add</span>
        </Button>
        <LastEditedBy name="Mainstay Staff" date={lastEditedAt} />
      </div>
    </div>
  )
}

interface IDropDownIconProps {
  readonly state: 'collapsed' | 'expanded'
}
function DropDownIcon({ state }: IDropDownIconProps) {
  const rotation = state === 'collapsed' ? -90 : 90
  return (
    <AHIcon
      name="navigate_before"
      className="d-flex-inline pointer"
      rotate={rotation}
    />
  )
}

interface ISuggestedAnswersDropDownProps {
  readonly suggestedAnswers: ReadonlyArray<{
    id: number
    answer: string
    modified: string
  }>
  copyAllAnswers: () => void
  showFallbackResponseTips?: boolean
  copyAnswer: (params: { answerId: number; approve?: boolean }) => void
  readonly defaultExpanded: boolean
  innerRef?: React.RefObject<HTMLDivElement>
}

export function SuggestedAnswersDropdown({
  suggestedAnswers,
  copyAllAnswers,
  copyAnswer,
  defaultExpanded,
  showFallbackResponseTips,
  innerRef,
}: ISuggestedAnswersDropDownProps) {
  const [expanded, setExpanded] = useState(false)
  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(defaultExpanded)
    }
  }, [defaultExpanded])

  function toggleExpanded() {
    setExpanded(s => !s)
  }
  return (
    <div className="rounded bg-blue-grey-005 my-4 pointer" ref={innerRef}>
      <div onClick={toggleExpanded} className="p-3 hover-bg-blue-grey-050">
        <div className="d-flex justify-content-between">
          <span>Not sure what to write?</span>
          <DropDownIcon state={expanded ? 'expanded' : 'collapsed'} />
        </div>
        <span className="caption">Check out these suggested responses.</span>
      </div>

      {expanded ? (
        <div className="p-3">
          {showFallbackResponseTips && <FallbackResponseTips />}

          {suggestedAnswers.map(x => (
            <AdmitHubGlobalAnswer
              answer={x.answer}
              key={x.id}
              copyAnswer={() => copyAnswer({ answerId: x.id })}
              lastEditedAt={x.modified}
            />
          ))}
          {suggestedAnswers.length > 1 ? (
            <Button color="primary" outlined onClick={copyAllAnswers}>
              Copy All to my Account
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

const FallbackResponseTips = () => {
  return (
    <div className="caption d-flex align-items-center justify-content-start py-2">
      <AHIcon name="help" className="ah-help-icon" />
      <span className="ml-2">
        Learn how to write{' '}
        <a href="https://support.mainstay.com/hc/en-us/articles/360044853551">
          helpful Fallback Responses
        </a>
      </span>
    </div>
  )
}

interface IAnswerResponseData {
  id: number
  answer?: string
  pendingAnswer?: string
  dialogId: string | null
  dialogName: string | null
  filter?: number
  approved: boolean
  modified: string
  order: number
  lastEditedBy?: {
    id: string
    name: string
  }
  contactFilter?: {
    id: number
    name: string
  }
  workflowSteps: ICampaignScriptStep[]
}

const fetchUnderstanding = createAsyncAction(
  '@@mascot/knowledge/understandings/fetchUnderstanding/request',
  '@@mascot/knowledge/understandings/fetchUnderstanding/success',
  '@@mascot/knowledge/understandings/fetchUnderstanding/failure'
)<
  void,
  {
    readonly institutionAbbreviation?: string
    readonly category: string
    readonly subCategory: string
    readonly sampleQuestion: string
    readonly topic: string
    answers: IAnswerResponseData[]
    readonly suggestedAnswers: ReadonlyArray<{
      readonly id: number
      readonly answer: string
      readonly modified: string
    }>
    readonly institutionQuestions: ReadonlyArray<{
      readonly id: number
      readonly question: string
    }>
    readonly globalQuestions: ReadonlyArray<{
      readonly id: number
      readonly enabled: boolean
      readonly question: string
    }>
  },
  void
>()

const setAnswers = createStandardAction(
  '@@mascot/knowledge/understandings/setAnswers'
)<IAnswerResponseData[]>()

const deleteAnswerAsync = createAsyncAction(
  '@@mascot/knowledge/understandings/ASYNCDELETEREQUEST',
  '@@mascot/knowledge/understandings/ASYNCDELETESUCCESS',
  '@@mascot/knowledge/understandings/ASYNCDELETEFAILURE'
)<{ id: number }, { readonly answerId: number }, void>()
const updateAnswerAsync = createAsyncAction(
  '@@mascot/knowledge/understandings/ASYNCUPDATEREQUEST',
  '@@mascot/knowledge/understandings/ASYNCUPDATESUCCESS',
  '@@mascot/knowledge/understandings/ASYNCUPDATEFAILURE'
)<{ id?: number; newAnswerText?: string }, IAnswerResponseData, void>()

const createAnswerAsync = createAsyncAction(
  '@@mascot/knowledge/understandings/ASYNCCREATEREQUEST',
  '@@mascot/knowledge/understandings/ASYNCCREATESUCCESS',
  '@@mascot/knowledge/understandings/ASYNCCREATEFAILURE'
)<
  { data: IUpdateOrCreateResponseRequest; addedAsPersonalized?: boolean },
  IAnswerResponseData,
  void
>()

const reorderAnswersAsync = createAsyncAction(
  '@@mascot/knowledge/understandings/ASYNCREORDERREQUEST',
  '@@mascot/knowledge/understandings/ASYNCREORDERSUCCESS',
  '@@mascot/knowledge/understandings/ASYNCREORDERFAILURE'
)<number[], { id: number; order: number }[], void>()

const updateAddingDefault = createStandardAction(
  '@@mascot/knowledge/understandings/updateAddingDefault'
)<{ enabled: boolean; answerUUID: string }>()
const updateAddingPersonalized = createStandardAction(
  '@@mascot/knowledge/understandings/updateAddingPersonalized'
)<{ enabled: boolean; answerUUID: string }>()

const setAnimationEnabled = createStandardAction(
  '@@mascot/knowledge/understandings/setAnimationEnabled'
)<boolean>()

const setAnswerAsEditing = createStandardAction(
  '@@mascot/knowledge/understandings/setAnswerAsEditing'
)<{ editing: boolean; answerId: number }>()

export const updateGlobalQuestion = createStandardAction(
  '@@mascot/knowledge/understandings/updateGlobalQuestion'
)<{
  readonly id: number
  readonly enabled: boolean
}>()
export const updateInstitutionQuestion = createStandardAction(
  '@@mascot/knowledge/understandings/updateInstitutionQuestion'
)<{
  readonly id: number
  readonly question: string
}>()
export const deleteInstitutionQuestion = createStandardAction(
  '@@mascot/knowledge/understandings/deleteInstitutionQuestion'
)<{ readonly id: number }>()
export const addGeneratedInstitutionQuestions = createStandardAction(
  '@@mascot/knowledge/understandings/addGeneratedInstitutionQuestions'
)<
  {
    readonly id: number
    readonly question: string
  }[]
>()

type IKnowledgeSeedEditPanelActions =
  | ActionType<typeof fetchUnderstanding>
  | ActionType<typeof setAnswers>
  | ActionType<typeof updateAddingDefault>
  | ActionType<typeof updateAddingPersonalized>
  | ActionType<typeof updateGlobalQuestion>
  | ActionType<typeof updateInstitutionQuestion>
  | ActionType<typeof deleteInstitutionQuestion>
  | ActionType<typeof addGeneratedInstitutionQuestions>
  | ActionType<typeof updateAnswerAsync>
  | ActionType<typeof createAnswerAsync>
  | ActionType<typeof deleteAnswerAsync>
  | ActionType<typeof reorderAnswersAsync>
  | ActionType<typeof setAnimationEnabled>
  | ActionType<typeof setAnswerAsEditing>

type knowledgeSeedStateGlobalQuestions = ReadonlyArray<{
  readonly id: number
  readonly enabled: boolean
  readonly question: string
}>

export interface IQuestionMetadata {
  created: string
  modified: string
  external_id: string
}

export interface IKnowledgeSeedEditPanelState {
  readonly view: 'loading' | 'failure' | 'success'
  readonly institutionAbbreviation: string | null
  readonly sampleQuestion: string
  readonly category: string
  readonly subCategory: string
  answers: ReadonlyArray<IAnswerResponseData>
  readonly suggestedAnswers: ReadonlyArray<{
    readonly id: number
    readonly answer: string
    readonly modified: string
  }>
  readonly institutionQuestions: ReadonlyArray<{
    id: number
    question: string
    generated?: boolean
    created?: string
    modified?: string
    metadata?: IQuestionMetadata
  }>
  readonly topic: string
  readonly enabledGlobalQuestions: knowledgeSeedStateGlobalQuestions
  readonly disabledGlobalQuestions: knowledgeSeedStateGlobalQuestions
  readonly actionInProgress: boolean
  readonly answersBeingEdited: number[]
  readonly answersPendingRequest: number[]
  readonly reordering: boolean
  readonly addingDefaultResponse: boolean
  readonly addingPersonalizedResponse: boolean
  readonly enableAnimation: boolean
  readonly newAnswerUUID?: string
  readonly newAnswerRef?: React.RefObject<HTMLDivElement>
  readonly answerPendingCreation?: {
    data: IAnswerProps
    addedAsPersonalized?: boolean
  }
  animationUUIDMap: { [key: number]: string } // Maps the UUID of newly created answers to their subsequent answer id
}

export const initialData: IKnowledgeSeedEditPanelState = {
  view: 'loading',
  institutionAbbreviation: null,
  sampleQuestion: '',
  topic: '',
  category: '',
  subCategory: '',
  answers: [],
  suggestedAnswers: [],
  institutionQuestions: [],
  enabledGlobalQuestions: [],
  disabledGlobalQuestions: [],
  answersBeingEdited: [],
  answersPendingRequest: [],
  reordering: false,
  actionInProgress: false,
  addingDefaultResponse: false,
  addingPersonalizedResponse: false,
  enableAnimation: false,
  animationUUIDMap: {},
}

export function knowledgeSeedEditPanelReducer(
  state: IKnowledgeSeedEditPanelState,
  action: IKnowledgeSeedEditPanelActions
): IKnowledgeSeedEditPanelState {
  switch (action.type) {
    case getType(createAnswerAsync.request):
      return {
        ...state,
        actionInProgress: true,
        answerPendingCreation: {
          addedAsPersonalized: action.payload.addedAsPersonalized,
          data: {
            ...action.payload.data,
            contactFilter: undefined,
            answerId: 0,
            dialogId: action.payload.data.dialogId || null,
            dialogName: '',
            actionInProgress: true,
            editing: false,
            workflowSteps: [],
            // tslint:disable-next-line no-empty
            onSave: () => {},
            onDelete: undefined,
          },
        },
      }
    case getType(deleteAnswerAsync.request): {
      return {
        ...state,
        answersBeingEdited: state.answersBeingEdited.filter(
          a => a !== action.payload.id
        ),
        answersPendingRequest: [
          ...state.answersPendingRequest,
          ...(action.payload.id ? [action.payload.id] : []),
        ],
      }
    }
    case getType(updateAnswerAsync.request): {
      return {
        ...state,
        answersBeingEdited: state.answersBeingEdited.filter(
          a => a !== action.payload.id
        ),
        answersPendingRequest: action.payload.id
          ? [...state.answersPendingRequest, action.payload.id]
          : state.answersPendingRequest,
        answers: action.payload.newAnswerText
          ? state.answers.map(a =>
              a.id === action.payload.id
                ? { ...a, pendingAnswer: action.payload.newAnswerText }
                : a
            )
          : state.answers,
      }
    }
    case getType(deleteAnswerAsync.success):
      return {
        ...state,
        answers: state.answers.filter(x => x.id !== action.payload.answerId),
        answersPendingRequest: state.answersPendingRequest.filter(
          id => id !== action.payload.answerId
        ),
        actionInProgress: false,
      }
    case getType(createAnswerAsync.success): {
      return {
        ...state,
        actionInProgress: false,
        addingDefaultResponse: false,
        addingPersonalizedResponse: false,
        answers: [...state.answers, action.payload],
        answersPendingRequest: state.answersPendingRequest.filter(
          id => id !== action.payload.id
        ),
        answerPendingCreation: undefined,
        animationUUIDMap: {
          ...state.animationUUIDMap,
          ...(state.newAnswerUUID
            ? { [action.payload.id]: state.newAnswerUUID }
            : {}),
        },
      }
    }
    case getType(updateAnswerAsync.success):
      const targetAnswer = state.answers.find(x => x.id === action.payload.id)
      if (targetAnswer == null) {
        return state
      }
      return {
        ...state,
        answers: [
          ...state.answers.filter(x => x.id !== action.payload.id),
          action.payload,
        ],
        answersPendingRequest: state.answersPendingRequest.filter(
          id => id !== action.payload.id
        ),
        actionInProgress: false,
      }
    case getType(deleteAnswerAsync.failure):
    case getType(createAnswerAsync.failure):
    case getType(updateAnswerAsync.failure): {
      return {
        ...state,
        actionInProgress: false,
        addingDefaultResponse: false,
        addingPersonalizedResponse: false,
        answersPendingRequest: [],
        answerPendingCreation: undefined,
      }
    }
    case getType(updateAddingDefault): {
      return {
        ...state,
        addingDefaultResponse: action.payload.enabled,
        ...(action.payload.enabled
          ? {
              newAnswerUUID: action.payload.answerUUID,
              newAnswerRef: React.createRef<HTMLDivElement>(),
            }
          : {}),
      }
    }
    case getType(updateAddingPersonalized): {
      return {
        ...state,
        addingPersonalizedResponse: action.payload.enabled,
        ...(action.payload.enabled
          ? {
              newAnswerUUID: action.payload.answerUUID,
              newAnswerRef: React.createRef<HTMLDivElement>(),
            }
          : {}),
      }
    }
    case getType(fetchUnderstanding.request):
      return { ...state, view: 'loading' }
    case getType(fetchUnderstanding.success): {
      return {
        ...state,
        topic: action.payload.topic,
        sampleQuestion: action.payload.sampleQuestion,
        category: action.payload.category,
        subCategory: action.payload.subCategory,
        answers: action.payload.answers,
        suggestedAnswers: action.payload.suggestedAnswers,
        institutionQuestions: action.payload.institutionQuestions,
        disabledGlobalQuestions: action.payload.globalQuestions.filter(
          x => !x.enabled
        ),
        enabledGlobalQuestions: action.payload.globalQuestions.filter(
          x => x.enabled
        ),
        institutionAbbreviation: action.payload.institutionAbbreviation ?? null,
        view: 'success',
      }
    }
    case getType(fetchUnderstanding.failure):
      return { ...state, view: 'failure' }
    case getType(setAnswers): {
      return {
        ...state,
        answers: action.payload,
      }
    }
    case getType(updateInstitutionQuestion): {
      return {
        ...state,
        institutionQuestions: [
          ...state.institutionQuestions.filter(x => x.id !== action.payload.id),
          { id: action.payload.id, question: action.payload.question },
        ],
      }
    }
    case getType(deleteInstitutionQuestion): {
      return {
        ...state,
        institutionQuestions: [
          ...state.institutionQuestions.filter(x => x.id !== action.payload.id),
        ],
      }
    }
    case getType(addGeneratedInstitutionQuestions): {
      return {
        ...state,
        institutionQuestions: [
          ...state.institutionQuestions,
          ...action.payload.map(q => ({ generated: true, ...q })),
        ],
      }
    }
    case getType(updateGlobalQuestion): {
      const globalQuestionEnabled = state.enabledGlobalQuestions.find(
        x => x.id === action.payload.id
      )
      const globalQuestionDisabled = state.disabledGlobalQuestions.find(
        x => x.id === action.payload.id
      )
      // if the question we're updating is enabled, update it. We don't want to
      // move the position of the question because that makes it difficult for
      // users to undo their changes. It would also cause a lot of confusing
      // jumping.
      if (globalQuestionEnabled) {
        return {
          ...state,
          enabledGlobalQuestions: [
            ...state.enabledGlobalQuestions.filter(
              x => x.id !== action.payload.id
            ),
            {
              ...globalQuestionEnabled,
              enabled: action.payload.enabled,
            },
          ],
        }
      }
      // if the quetion we're updating is disabled, update it.
      if (globalQuestionDisabled) {
        return {
          ...state,
          disabledGlobalQuestions: [
            ...state.disabledGlobalQuestions.filter(
              x => x.id !== action.payload.id
            ),
            {
              ...globalQuestionDisabled,
              enabled: action.payload.enabled,
            },
          ],
        }
      }
      return state
    }
    case getType(reorderAnswersAsync.request): {
      // Give a temporary order based on the request, so that the UI shows the
      // expected new order while waiting for the backend response.
      const tempAnswerOrderMap = action.payload.reduce(
        (acc: { [key: number]: number }, answerId, index) => {
          return {
            ...acc,
            [answerId]: action.payload.length - index,
          }
        },
        {}
      )
      return {
        ...state,
        reordering: true,
        answers: state.answers.map(ans =>
          tempAnswerOrderMap[ans.id]
            ? { ...ans, order: tempAnswerOrderMap[ans.id] }
            : ans
        ),
      }
    }
    case getType(reorderAnswersAsync.success): {
      const newAnswerToOrder = action.payload.reduce(
        (acc: { [key: number]: number }, item) => {
          return {
            ...acc,
            [item.id]: item.order,
          }
        },
        {}
      )
      return {
        ...state,
        reordering: false,
        enableAnimation: true,
        answers: state.answers.map(ans =>
          newAnswerToOrder[ans.id]
            ? { ...ans, order: newAnswerToOrder[ans.id] }
            : ans
        ),
      }
    }
    case getType(reorderAnswersAsync.failure): {
      return {
        ...state,
        enableAnimation: true,
        reordering: false,
      }
    }
    case getType(setAnimationEnabled): {
      return { ...state, enableAnimation: action.payload }
    }
    case getType(setAnswerAsEditing): {
      return {
        ...state,
        answersBeingEdited: action.payload.editing
          ? [...state.answersBeingEdited, action.payload.answerId]
          : state.answersBeingEdited.filter(a => a !== action.payload.answerId),
      }
    }
  }
}

function toastWarning(message: string) {
  toast(message, { type: 'error' })
}

function getDefaultAnswer({
  orgAnswers,
  suggestedAnswers,
}: {
  orgAnswers: readonly {
    readonly answer?: string
    readonly dialogId: string | null
    readonly dialogName: string | null
    readonly order?: number
  }[]
  suggestedAnswers: readonly { readonly answer: string }[]
}): {
  sampleAnswer: string | null
  sampleDialogId: string | null
  sampleDialogName: string | null
} | null {
  const firstOrgAnswer = orgAnswers.find(x => !!x.answer || !!x.dialogName)
  if (firstOrgAnswer && firstOrgAnswer.answer) {
    return {
      sampleAnswer: firstOrgAnswer.answer,
      sampleDialogId: null,
      sampleDialogName: null,
    }
  }
  if (firstOrgAnswer && firstOrgAnswer.dialogName) {
    return {
      sampleAnswer: null,
      sampleDialogId: firstOrgAnswer.dialogId,
      sampleDialogName: firstOrgAnswer.dialogName,
    }
  }
  if (suggestedAnswers.length > 0) {
    return {
      sampleAnswer: suggestedAnswers[0].answer,
      sampleDialogId: null,
      sampleDialogName: null,
    }
  }
  return null
}

export function useKnowledgeSeed({
  understandingId,
  onClose,
}: {
  understandingId: string
  onClose: () => void
}) {
  const [showMore, setShowMore] = useState(false)
  const [
    showUnderstandingDeleteConfModal,
    setShowUnderstandingDeleteConfModal,
  ] = useState<boolean>(false)
  const reduxDispatch = useDispatch()
  function archiveUnderstanding() {
    removeKnowledgeSeedAsync(understandingId)(reduxDispatch).then(res => {
      // close the understanding panel on success.
      if (res.ok) {
        onClose()
      }
    })
    setShowUnderstandingDeleteConfModal(false)
  }
  const [loadingDrawer, setLoadingDrawer] = React.useState(false)
  const [
    loadingGeneratedQuestions,
    setLoadingGeneratedQuestions,
  ] = React.useState(false)

  const [state, dispatch] = useReducer(
    knowledgeSeedEditPanelReducer,
    initialData
  )

  const highestAnswerOrder = state.answers.reduce(
    (highestOrder, answer) =>
      highestOrder > answer.order ? highestOrder : answer.order,
    0
  )

  useEffect(() => {
    dispatch(fetchUnderstanding.request())
    setLoadingDrawer(true)
    api
      .fetchSidebarUnderstanding({ understandingId })
      .then(res => {
        const payload = res.data
        dispatch(
          fetchUnderstanding.success({
            ...payload,
            institutionQuestions: payload.institutionQuestions ?? [],
            globalQuestions: payload.globalQuestions ?? [],
          })
        )
        setLoadingDrawer(false)
        handleSetAnimationEnabled(true)
      })
      .catch(() => {
        dispatch(fetchUnderstanding.failure())
        setLoadingDrawer(false)
      })
  }, [understandingId])

  useEffect(() => {
    // when loading the sidebar this prevents some glitchiness in the list view
    // with an existing answer being removed and then replaced as the sidebar
    // loads.
    if (state.view !== 'success') {
      return
    }
    const approvedAnswersFirst = orderBy(
      state.answers,
      ['approved', 'created'],
      ['desc', 'desc']
    )
    const sampleResponse = getDefaultAnswer({
      orgAnswers: approvedAnswersFirst,
      suggestedAnswers: state.suggestedAnswers,
    })
    if (!sampleResponse) {
      return
    }
    const hasApprovedAnswer = approvedAnswersFirst[0]?.approved ?? null
    reduxDispatch(
      updateKnowledgeSeedApproval({
        understandingId,
        hasApprovedAnswer,
        ...sampleResponse,
      })
    )
  }, [
    reduxDispatch,
    state.view,
    state.answers,
    state.suggestedAnswers,
    understandingId,
  ])

  function handleAnswerApproval({
    answerId,
    approved,
  }: {
    answerId: number
    approved: boolean
  }) {
    dispatch(updateAnswerAsync.request({ id: answerId }))
    api
      .updateUnderstandingApproval({ answerId, approved })
      .then(res => {
        dispatch(updateAnswerAsync.success(res.data))
      })
      .catch(() => {
        toastWarning('Answer approval failed.')
        dispatch(updateAnswerAsync.failure())
      })
  }

  const handleShowEditor = (payload: {
    editing: boolean
    answerId: number
  }) => {
    dispatch(setAnswerAsEditing(payload))
  }

  const handleUpdateAddingDefault = (enabled: boolean) => {
    const answerUUID = uuid()
    dispatch(updateAddingDefault({ enabled, answerUUID }))
  }
  const handleUpdateAddingPersonalized = (enabled: boolean) => {
    const answerUUID = uuid()
    dispatch(updateAddingPersonalized({ enabled, answerUUID }))
  }

  const handleSetAnimationEnabled = (payload: boolean) => {
    dispatch(setAnimationEnabled(payload))
  }

  const handleReorderingAnswers = (payload: number[]) => {
    dispatch(reorderAnswersAsync.request(payload))
    api
      .reorderAnswers({ understandingId, answerIds: payload })
      .then(res => {
        if (isRight(res)) {
          dispatch(reorderAnswersAsync.success(res.right))
        } else {
          toastWarning('Failed to reorder answers.')
          dispatch(reorderAnswersAsync.failure())
        }
      })
      .catch(() => {
        toastWarning('Failed to reorder answers.')
        dispatch(reorderAnswersAsync.failure())
      })
  }

  function handleUpdateAnswer(
    {
      id,
      answer,
      dialogId,
      approved,
      contactFilter,
      order,
      genAITransactionId,
    }: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean
  ) {
    dispatch(updateAnswerAsync.request({ id, newAnswerText: answer }))
    if (id !== undefined) {
      api
        .understandingUpdateAnswer({
          answerId: id,
          answer,
          dialogId,
          approved,
          contactFilter,
          order: moveToTop ? 1 + highestAnswerOrder : order,
          genAITransactionId,
        })
        .then(res => {
          dispatch(updateAnswerAsync.success(res.data))
        })
        .catch(() => {
          toastWarning('Answer update failed.')
          dispatch(updateAnswerAsync.failure())
        })
    }
  }

  function handleCopyAllAnswers({
    approve = false,
  }: { approve?: boolean } = {}) {
    api
      .understandingCopyGlobalAnswersToInstitution({
        understandingId,
        global_answer_ids: state.suggestedAnswers.map(x => x.id),
        approveAll: approve,
      })
      .then(res => {
        dispatch(setAnswers(res.data.institutionAnswers))
      })
      .catch(() => {
        toastWarning('Copying answers failed.')
      })
  }
  function handleCopyAnswer({
    answerId,
    approve = false,
  }: {
    answerId: number
    approve?: boolean
  }) {
    return api
      .understandingCopyGlobalAnswersToInstitution({
        understandingId,
        global_answer_ids: [answerId],
        approveAll: approve,
      })
      .then(res => {
        dispatch(setAnswers(res.data.institutionAnswers))
      })
      .catch(() => {
        toastWarning('Copy answer failed.')
      })
  }
  function handleAnswerDelete({ answerId }: { answerId: number }) {
    dispatch(deleteAnswerAsync.request({ id: answerId }))
    api
      .understandingDeleteAnswer({ answerId })
      .then(() => {
        dispatch(deleteAnswerAsync.success({ answerId }))
      })
      .catch(() => {
        toastWarning('Answer deletion failed.')
        dispatch(deleteAnswerAsync.failure())
      })
  }
  function handleAnswerCreation(
    payload: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean,
    addedAsPersonalized?: boolean
  ) {
    dispatch(createAnswerAsync.request({ data: payload, addedAsPersonalized }))
    api
      .understandingCreateAnswer({
        ...payload,
        understandingId,
        order: moveToTop ? 1 + highestAnswerOrder : payload.order,
      })
      .then(res => {
        dispatch(createAnswerAsync.success(res.data))
      })
      .catch(() => {
        toastWarning('Answer creation failed.')
        dispatch(createAnswerAsync.failure())
      })
  }

  function getEnabledStatus(questionId: number): boolean | null {
    const disabledQuestion = state.disabledGlobalQuestions.find(
      x => x.id === questionId
    )
    const enabledQuestion = state.enabledGlobalQuestions.find(
      x => x.id === questionId
    )
    if (disabledQuestion) {
      return disabledQuestion.enabled
    }
    if (enabledQuestion) {
      return enabledQuestion.enabled
    }
    return null
  }

  const handleUpdateGlobalQuestion = (
    id: number,
    newEnabledStatus: boolean
  ) => {
    // Optimisitically update the enabled state and revert the change on failure.
    const oldEnableStatus = getEnabledStatus(id)
    dispatch(
      updateGlobalQuestion({
        id,
        enabled: newEnabledStatus,
      })
    )
    api
      .updateGlobalQuestion({ questionId: id, enabled: newEnabledStatus })
      .then(res => {
        dispatch(
          updateGlobalQuestion({
            id: res.data.id,
            enabled: res.data.enabled,
          })
        )
      })
      .catch(() => {
        dispatch(
          updateGlobalQuestion({
            id,
            enabled: oldEnableStatus ?? newEnabledStatus,
          })
        )
        toastWarning('Question update failed.')
      })
  }

  function handleUpdateInstitutionQuestion({
    id,
    question,
  }: {
    readonly id: number
    readonly question: string
  }) {
    dispatch(updateInstitutionQuestion({ id, question }))
  }

  function handleDeleteInstitutionQuestion(questionId: number) {
    // We optimisically delete the question and restore the understanding if we fail.
    const originalQuestion = state.institutionQuestions.find(
      x => x.id === questionId
    )
    dispatch(deleteInstitutionQuestion({ id: questionId }))
    api.deleteOrganizationQuestion({ questionId }).catch(() => {
      toastWarning('Problem deleting question')
      if (originalQuestion != null) {
        dispatch(updateInstitutionQuestion(originalQuestion))
      }
    })
  }

  function handleAddGeneratedInstitutionQuestions() {
    setLoadingGeneratedQuestions(true)
    api.generativeTextService
      .generateFuzzyQuestions({
        understandingId,
      })
      .then(res => {
        setLoadingGeneratedQuestions(false)
        if (isLeft(res)) {
          toast('Error generating questions', { type: 'error' })
        } else {
          dispatch(addGeneratedInstitutionQuestions(res.right.fuzzy_questions))
        }
      })
  }

  return {
    state,

    showMore,
    setShowMore,

    loadingDrawer,
    loadingGeneratedQuestions,

    handleShowEditor,

    handleUpdateAddingDefault,
    handleUpdateAddingPersonalized,
    handleSetAnimationEnabled,

    handleUpdateAnswer,
    handleAnswerApproval,
    handleAnswerCreation,
    handleAnswerDelete,
    handleCopyAllAnswers,
    handleCopyAnswer,
    archiveUnderstanding,
    updateGlobalQuestion: handleUpdateGlobalQuestion,
    updateInstitutionQuestion: handleUpdateInstitutionQuestion,
    deleteInstitutionQuestion: handleDeleteInstitutionQuestion,
    addGeneratedInstitutionQuestions: handleAddGeneratedInstitutionQuestions,
    handleReorderingAnswers,
    showUnderstandingDeleteConfModal,
    setShowUnderstandingDeleteConfModal,
  }
}
export const DEFAULT_QUESTION_COUNT_SHOWN = 5

export function AddFuzzyQuestionButton({
  onClick,
  disabled,
  className,
}: {
  onClick: () => void
  disabled?: boolean
  className?: string
}) {
  return (
    <Button
      color="link"
      className={classNames(
        'p-0 mb-3 d-flex align-items-center text-decoration-none',
        className
      )}
      onClick={onClick}
      disabled={disabled}>
      <AHIcon className="pointer" name="add_circle_outline" />
      <span>Add Question</span>
    </Button>
  )
}

export const isAnswerPersonalized = (
  answer: Pick<IAnswerResponseData, 'contactFilter' | 'answer'>,
  mapping: IAttributesById
) => {
  const hasContactFilter = !!answer.contactFilter?.id
  const hasContactAttributes =
    answer.answer && getHasContactAttribute(answer.answer, mapping)
  return hasContactFilter || !!hasContactAttributes
}

export const getSortedPartitionedAnswers = (
  answers: readonly IAnswerResponseData[],
  mapping: IAttributesById
): [IAnswerResponseData[], IAnswerResponseData[]] => {
  const [personalizedAnswers, unpersonalizedAnswers] = partition(answers, x =>
    isAnswerPersonalized(x, mapping)
  )

  return [
    orderBy(personalizedAnswers, ['order', 'created'], ['desc', 'asc']),
    orderBy(unpersonalizedAnswers, ['order', 'created'], ['desc', 'asc']),
  ]
}
