import React from 'react'
import 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel.scss'
import {
  ISegmentOption,
  ReadOnlyContactSegmentSelect,
} from 'components/ContactSegmentSelect/ContactSegmentSelect'
import { useParseAnswer } from 'components/DraftEditor/draftUtils'
import { ResponseCard } from 'components/ResponseCard/ResponseCard'
import { BasicResponseHeader } from 'components/ResponseCard/ResponseCardHeader'
import {
  BasicResponseCardContentReadMode,
  BasicResponseCardContentWriteMode,
  ConditionalEditDisplay,
  ScriptResponseContent,
} from 'components/ResponseCard/ResponseCardContent'
import {
  IAnswerSaveButtonProps,
  ResponseCardFooter,
  ScriptResultsSection,
} from 'components/ResponseCard/ResponseCardFooter'
import { Button } from 'components/Button/Button'
import classNames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { IAutoSuggestOption } from 'components/AutoSuggest/AutoSuggest'
import { IDialogForUnknownContact } from 'api/response'
import { ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { AlertBanner, AlertType } from 'components/Alert/Alert'
import { isUndefined } from 'lodash'
import { noOp } from 'util/noOp'
import { IDeleteButtonProps } from 'components/FloatingTrashCan/FloatingTrashCan'
import { GenAIDraftEditorProps } from 'components/DraftEditor/DraftEditor'

export const defaultHelpMessage = (
  <span>
    Default responses can be sent to any contact, and will be sent if no
    personalized responses are available. Responses from this section are chosen
    at random.
  </span>
)
export const personalizedHelpMessage = (
  <span>
    Share a response with a particular contact group, or use information from a
    contact's profile. Contacts will receive the first personalized response for
    which they meet all criteria.
  </span>
)

export interface IUpdateOrCreateResponseRequest {
  id: number | undefined
  answer?: string
  dialogId?: string
  approved: boolean
  contactFilter?: number
  order?: number
  genAITransactionId?: number
}

interface IKBResponseWriteProps {
  readonly className?: string
  readonly answerId?: number
  readonly initialAnswer?: string
  readonly dialogId: string | null
  readonly dialogName: string | null
  readonly approved: boolean
  readonly selectedFilter?: number
  readonly onCancel: () => void
  readonly onSave: (
    request: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean
  ) => void
  readonly onDelete: (() => void) | undefined
  readonly lastModifiedByName?: string
  readonly lastModifiedAt?: string
  readonly lastEmbeddedAt?: string | null
  readonly hideApproval?: boolean
  readonly allowPersonalization?: boolean
  readonly addingPersonalizedResponse?: boolean
  readonly workflowSteps: ICampaignScriptStep[]
  readonly dragHandleProps?: DraggableProvidedDragHandleProps
  readonly deleteButtonProps?: IDeleteButtonProps
  readonly shouldBePersonalized?: boolean
  readonly genAIProps?: GenAIDraftEditorProps
}

export function KBResponseWrite({
  className,
  answerId,
  initialAnswer,
  approved,
  selectedFilter,
  onCancel,
  onSave,
  lastModifiedByName,
  lastModifiedAt,
  lastEmbeddedAt,
  hideApproval = false,
  onDelete,
  dialogId,
  dialogName,
  allowPersonalization = true,
  addingPersonalizedResponse = false,
  workflowSteps,
  dragHandleProps,
  deleteButtonProps,
  shouldBePersonalized,
  genAIProps,
}: IKBResponseWriteProps) {
  const [localAnswer] = React.useState<string | undefined>(initialAnswer)
  const [steps, setSteps] = React.useState(workflowSteps)
  const [editingAnswer, setEditingAnswer] = React.useState<string | undefined>(
    initialAnswer
  )
  const [selectedSegment, setSelectedSegment] = React.useState<
    number | undefined
  >(selectedFilter)
  const [editingApproval, setEditingApproval] = React.useState<boolean>(
    approved
  )
  const [showAudienceSelector, setShowAudienceSelector] = React.useState(
    selectedSegment !== undefined
  )

  const {
    selectedContactAttributes,
    hasValidAttributes: hasValidMustacheTemplate,
  } = useParseAnswer(editingAnswer)

  const [isScriptResponse, setScriptResponse] = React.useState(!!dialogId)
  const [script, setScript] = React.useState<
    IAutoSuggestOption<IDialogForUnknownContact>
  >({ value: dialogId || '', label: dialogName || '' })

  const currentlyHasContactAttributes = selectedContactAttributes.length > 0
  const isPersonalized = !!selectedSegment || currentlyHasContactAttributes

  const onSelectSegmentChange = (option?: ISegmentOption) => {
    const newfilterId = option?.value
    setSelectedSegment(newfilterId)
  }

  function handleSave() {
    let payload: IUpdateOrCreateResponseRequest = {
      id: undefined,
      answer: !isScriptResponse ? editingAnswer : undefined,
      dialogId: isScriptResponse ? script.value : undefined,
      approved: editingApproval,
      contactFilter: selectedSegment,
    }
    if (answerId !== undefined) {
      payload = { ...payload, id: answerId }
    }
    // If the user was adding a unpersonalized answer but then personalized it,
    // we should put it at the top of the personalized list.
    const moveToTop = !addingPersonalizedResponse && isPersonalized
    onSave(payload, !!moveToTop)
  }

  return (
    <ResponseCard
      mode="write"
      className={classNames(className, 'mt-3')}
      loading={false}
      // in the case of creation, we only handle approvals locally
      // in the case of update, we handle approvals w/ the passed in onChangeApprovalProp
      // either way, the editingApproval state variable will be updated and sent to the onSave()
      approved={editingApproval}
      onApprove={() => setEditingApproval(true)}
      onUnapprove={() => setEditingApproval(false)}
      lastModifiedAt={lastModifiedAt}
      lastModifiedByName={lastModifiedByName}
      lastEmbeddedAt={lastEmbeddedAt}
      hideApproval={hideApproval}
      header={
        <BasicResponseHeader
          onDelete={onDelete}
          editing={true}
          dragHandleProps={dragHandleProps}
          deleteButtonProps={deleteButtonProps}
          audience={
            allowPersonalization
              ? {
                  selector: (
                    <>
                      <div className="px-3 pt-0 mb-3">
                        {!showAudienceSelector ? (
                          <span
                            color="link"
                            onClick={() => setShowAudienceSelector(true)}
                            className="pl-0 text-left text-secondary-teal pointer text-underline-on-hover">
                            Set an Audience
                          </span>
                        ) : (
                          <div className="mt-1">
                            <ReadOnlyContactSegmentSelect
                              selectedFilterId={selectedSegment}
                              onChange={onSelectSegmentChange}
                              onNewSegment={noOp}
                              placeholder="Audience Name"
                              showCreateNewButton={false}
                              className="z-index-10"
                              isClearable
                            />
                          </div>
                        )}
                      </div>
                      <hr className="m-0" />
                    </>
                  ),
                  targetAudienceInfo: (
                    <div className="px-3 mt-3 position-relative pb-1 caption">
                      Who can receive this response?
                    </div>
                  ),
                }
              : null
          }
        />
      }
      content={
        <ConditionalEditDisplay
          className="mt-3"
          toggled={isScriptResponse}
          info="Make responses interactive by using a Script."
          editing={true}
          onToggle={() => setScriptResponse(val => !val)}
          title="Provide an Interactive Response"
          off={
            <>
              {
                <BasicResponseCardContentWriteMode
                  response={localAnswer || ''}
                  hasContactAttributes={currentlyHasContactAttributes}
                  onChangeAnswer={setEditingAnswer}
                  disableAttributes={!allowPersonalization}
                  genAIProps={genAIProps}
                />
              }{' '}
            </>
          }
          on={
            <ScriptResponseContent
              label="Response"
              onChange={val => {
                if (val.target.data && !Array.isArray(val.target.data)) {
                  setScript(val.target.data)
                  setSteps([])
                }
              }}
              value={script}
              name="inputName"
              editing={true}>
              {steps && steps.length > 0 && (
                <ScriptResultsSection
                  title="Script Survey Results"
                  info="If this script was also used in a campaign those results will appear here."
                  steps={steps.map(x => ({
                    choices: x.choiceResponseCounts ?? null,
                    prompt: x.prompt,
                    personalizedPrompt: x.personalizedPrompt,
                    promptType: x.promptType,
                    id: x.id,
                    count: x.totalResponseCount || 0,
                    totalResponseCount: x.totalResponseCount || 0,
                  }))}
                />
              )}
            </ScriptResponseContent>
          }
        />
      }
      footer={
        <>
          {!hasValidMustacheTemplate && (
            <AlertBanner
              className="mx-3 mb-3"
              type={AlertType.Danger}
              subtitle={
                "Invalid templating. Please remove superfluous '{' and '}' characters and use attribute selectors instead."
              }
            />
          )}
          <ResponseCardFooter
            onSave={handleSave}
            saveButtonProps={getSaveButtonState()}
            onCancel={onCancel}
          />
        </>
      }
    />
  )

  function getSaveButtonState(): IAnswerSaveButtonProps {
    if (!isScriptResponse && !editingAnswer) {
      return { disabled: true, tooltipText: 'Please provide an answer.' }
    }
    if (!!(shouldBePersonalized && !isPersonalized)) {
      return {
        disabled: true,
        tooltipText:
          'Personalized responses must have an audience or a custom field.',
      }
    }
    if (!hasValidMustacheTemplate) {
      return {
        disabled: true,
        tooltipText:
          "Invalid templating. Please remove superfluous '{' and '}' characters and use attribute selectors instead.",
      }
    }
    if (isScriptResponse && !script.value) {
      return {
        disabled: true,
        tooltipText: 'Please select an interactive response.',
      }
    }
    return { disabled: false, tooltipText: undefined }
  }
}

interface IKBResponseReadProps {
  onApprove: () => void
  onUnapprove: () => void
  onDelete: (() => void) | undefined
  answer?: string
  dialogId: string | null
  dialogName: string | null
  approved: boolean
  lastModifiedByName?: string
  lastModifiedAt?: string
  lastEmbeddedAt?: string | null
  hideApproval?: boolean
  audience?: React.ReactElement
  contactFilterName?: string
  readonly deleteButtonProps?: IDeleteButtonProps
  readonly allowPersonalization?: boolean
  readonly workflowSteps: ICampaignScriptStep[]
  readonly dragHandleProps: DraggableProvidedDragHandleProps | undefined
}
export function KBResponseRead({
  answer,
  approved,
  onUnapprove,
  onApprove,
  onDelete,
  lastModifiedByName,
  lastModifiedAt,
  lastEmbeddedAt,
  hideApproval = false,
  allowPersonalization = true,
  audience,
  contactFilterName,
  workflowSteps,
  dialogId,
  dialogName,
  dragHandleProps,
  deleteButtonProps,
}: IKBResponseReadProps) {
  const targetAudienceInfo = allowPersonalization ? (
    <div className="py-3 px-3 position-relative">
      <div className="caption">Who can receive this response?</div>
      <p className="m-0">
        {contactFilterName || (audience ? null : 'All Contacts')}
      </p>
      <div>{audience}</div>
    </div>
  ) : null

  return (
    <ResponseCard
      mode="read"
      className="hover-bg-blue-grey-050 mb-2"
      approved={approved}
      loading={false}
      onApprove={onApprove}
      onUnapprove={onUnapprove}
      lastModifiedAt={lastModifiedAt}
      lastModifiedByName={lastModifiedByName}
      lastEmbeddedAt={lastEmbeddedAt}
      hideApproval={hideApproval}
      header={
        <BasicResponseHeader
          editing={false}
          onDelete={onDelete}
          audience={{ targetAudienceInfo }}
          dragHandleProps={dragHandleProps}
          deleteButtonProps={deleteButtonProps}
        />
      }
      content={
        <ConditionalEditDisplay
          toggled={!!dialogId}
          editing={false}
          title="Provide an Interactive Response"
          info="Make responses interactive by using a Script."
          off={
            <>
              {!isUndefined(answer) && (
                <BasicResponseCardContentReadMode response={answer} />
              )}
            </>
          }
          on={
            <ScriptResponseContent
              value={
                (dialogId &&
                  dialogName && { value: dialogId, label: dialogName }) ||
                undefined
              }
              name="inputName"
              label="Response"
              editing={false}>
              <div
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}>
                {workflowSteps && workflowSteps.length > 0 && (
                  <ScriptResultsSection
                    title="Script Survey Results"
                    info="If this script was also used in a campaign those results will appear here."
                    dialogId={dialogId}
                    downloadResults={true}
                    steps={workflowSteps.map(x => ({
                      choices: x.choiceResponseCounts ?? null,
                      prompt: x.prompt,
                      personalizedPrompt: x.personalizedPrompt,
                      promptType: x.promptType,
                      id: x.id,
                      count: x.totalResponseCount || 0,
                      totalResponseCount: x.totalResponseCount || 0,
                    }))}
                  />
                )}
              </div>
            </ScriptResponseContent>
          }
        />
      }
    />
  )
}

interface IAddResponseButtonProps {
  disabled: boolean
  onClick: () => void
  className?: string
  innerRef?: React.Ref<HTMLButtonElement>
}

export const AddResponseButton = ({
  onClick,
  disabled,
  className,
  innerRef,
}: IAddResponseButtonProps) => (
  <PermissionsGuard permission={PERMISSIONS.UNDERSTANDING.CREATE}>
    <Button
      color="link"
      ref={innerRef}
      className={classNames(
        className,
        'p-0 mt-3 d-flex align-items-center text-decoration-none',
        { disabled }
      )}
      onClick={onClick}>
      <AHIcon className="pointer" name="add_circle_outline" />
      <span>Add Response</span>
    </Button>
  </PermissionsGuard>
)
