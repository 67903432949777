import * as Api from 'api'
import {
  IContactAttributeResponseData,
  ISaveableTopLevelContactFieldsResponseData,
} from 'api/response'
import classNames from 'classnames'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import AutocompleteWithCreateModal from 'components/AutocompleteWithModalForm/AutocompleteWithModalForm'
import { Button } from 'components/Button/Button'
import {
  ContactAttributeOption,
  CreateContactAttributeForm,
  ICreateContactAttributeForm,
} from 'components/ExitActions/CreateContactAttributeModal'
import IncomingNode from 'components/IncomingNode/IncomingNode'
import { Input } from 'components/Input/Input'
import NodeSubtree, {
  HorizontalNode,
  VerticalLine,
  VerticalNode,
} from 'components/NodeSubtree/NodeSubtree'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { Select as HouseSelect } from 'components/Select/Select'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import { Formik } from 'formik'
import * as React from 'react'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  ICampaignScriptStep,
  IExitAction,
  ISaveExitActionArgs,
  PromptType,
  IContactAttributeMapping,
} from 'store/campaign-scripts/reducer'
import { mapResponseDataToAttributes } from 'store/personalization/contactAttributes/reducer'
import {
  ContactAttributeType,
  IContactAttribute,
  IContactAttributeOption,
  getContactAttributesRes,
  getSaveableTopLevelFieldsRes,
} from 'store/personalization/contactAttributes/selectors'
import { AxiosResponse } from 'typings/axios'
import { isRight } from 'fp-ts/lib/Either'
import { AlertBanner, AlertType, InfoAlert } from 'components/Alert/Alert'
import { capitalize } from 'lodash'
import { ReadOnlyDatePicker } from 'components/ReadOnlyDatePicker/ReadOnlyDatePicker'
import moment from 'moment'
import { useDispatch, useSelector } from 'util/hooks'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import strftime from 'strftime'
import * as nope from 'yup'
import { ContactAttributeInputValidationSchema } from 'components/AttributeDrawer/AttributeFormValidationSchema'
import { LabeledTextInput } from 'components/LabeledInput/LabeledInput'
import { parse } from 'date-fns'
import { isLoading, isSuccess } from 'store/webdata'
import { getAllContactAttributesAsync } from 'store/personalization/contactAttributes/thunks'
import { useGetAttributeMapping } from 'components/DraftEditor/DraftEditor'

interface ISaveProps {
  step: ICampaignScriptStep
  action: IExitAction<ISaveExitActionArgs>
  actionIndex: number
  editable: boolean
  onCancel: () => void
  editMode: boolean
  setEditMode: (val: boolean) => void
  onUpdateNode: (data: Partial<ICampaignScriptStep>) => void
  border?: boolean
}

export const Save = ({
  step,
  action,
  actionIndex,
  editable,
  editMode,
  onUpdateNode,
  setEditMode,
  onCancel,
  border,
}: ISaveProps) => {
  useGetAttributeMapping()
  const contactAttributes = useSelector(getContactAttributesRes)
  const topLevelFields = useSelector(getSaveableTopLevelFieldsRes)

  const promptOptions = React.useMemo(
    () =>
      step.promptType === 'Boolean'
        ? ['Yes', 'No']
        : step.multipleChoices?.map(m => m.prompt),
    [step.multipleChoices, step.promptType]
  )

  const handleUpdateAction = React.useCallback(
    (updatedAction: IExitAction) => {
      const newExitActions = step.exitActions || []
      const { newlyCreated, ...cleanedUpdatedAction } = updatedAction
      newExitActions[actionIndex] = cleanedUpdatedAction
      onUpdateNode({ exitActions: newExitActions })
    },
    [actionIndex, onUpdateNode, step.exitActions]
  )

  const handleDeleteAction = React.useCallback(() => {
    const newExitActions = (step.exitActions || []).filter(
      (_, i: number) => i !== actionIndex
    )
    onUpdateNode({ exitActions: newExitActions })
  }, [actionIndex, onUpdateNode, step.exitActions])

  const handleClick = React.useCallback(() => editable && setEditMode(true), [
    setEditMode,
    editable,
  ])

  const attribute = React.useMemo(() => {
    if (!isSuccess(contactAttributes) || !isSuccess(topLevelFields)) {
      return null
    }
    return action.args.attributeId
      ? contactAttributes.data.find(elem => elem.id === action.args.attributeId)
      : null
  }, [contactAttributes, topLevelFields, action.args.attributeId])

  const saveFieldName = React.useMemo(() => {
    if (!isSuccess(contactAttributes)) {
      return 'Loading Attributes...'
    }
    return (attribute ? attribute?.name : action.args.field) ?? '(unknown)'
  }, [contactAttributes, attribute, action.args.field])

  if (action.newlyCreated || editMode) {
    return (
      <div>
        <SaveActionForm
          action={action}
          promptType={step.promptType}
          promptOptions={promptOptions || []}
          onCancel={onCancel}
          actionIndex={actionIndex}
          onUpdateAction={handleUpdateAction}
          onDeleteAction={handleDeleteAction}
          contactAttributes={
            isSuccess(contactAttributes) ? contactAttributes.data : []
          }
          topLevelFields={isSuccess(topLevelFields) ? topLevelFields.data : []}
          saveFieldName={saveFieldName}
        />
      </div>
    )
  }
  if (action.args.unset && action.args.field) {
    return (
      <UnsetField
        loading={isLoading(topLevelFields) || isLoading(contactAttributes)}
        onClickEdit={handleClick}
        editable={editable}
        field={action.args.field}
      />
    )
  }
  if (action.args.field && action.args.value) {
    return (
      <SaveStaticValue
        loading={isLoading(topLevelFields) || isLoading(contactAttributes)}
        isDate={attribute?.data_type === 'DATE'}
        onClickEdit={handleClick}
        field={action.args.field}
        value={action.args.value}
        editable={editable}
      />
    )
  }
  return (
    <div>
      {(step.promptType === PromptType.open &&
        action.reprompt &&
        action.args.attributeId) ||
      action.args.field === 'email' ? (
        <ValidationFlowSubtree
          border={border}
          onClickEdit={handleClick}
          editable={editable}
          saveFieldName={saveFieldName}
          attributeId={action.args.attributeId}
        />
      ) : (
        <>
          <VerticalLine />
          <SideEffectNode onClick={handleClick} editable={editable}>
            <div className="d-flex align-center">
              Save responses as {saveFieldName}
            </div>
          </SideEffectNode>
        </>
      )}
    </div>
  )
}

interface ISaveStaticValueProps {
  loading: boolean
  field: string
  value: string
  isDate: boolean
  editable: boolean
  onClickEdit: () => void
}

export const SaveStaticValue = ({
  loading,
  field,
  value,
  isDate,
  editable,
  onClickEdit,
}: ISaveStaticValueProps) => {
  const dateFormat = useSelector(getInstitutionDateFormat)
  const displayValue =
    isDate && value
      ? strftime(dateFormat, parse(value, 'yyyy-MM-dd', new Date()))
      : value
  const text = loading ? 'Loading...' : `Save '${displayValue}' as ${field}`
  return (
    <div onClick={onClickEdit}>
      <VerticalLine />
      <SideEffectNode editable={editable}>{text}</SideEffectNode>
    </div>
  )
}

export const UnsetField = ({
  loading,
  editable,
  field,
  onClickEdit,
}: Pick<
  ISaveStaticValueProps,
  'loading' | 'editable' | 'onClickEdit' | 'field'
>) => {
  const text = loading ? 'Loading...' : `Unset '${field}'`
  return (
    <div onClick={onClickEdit}>
      <VerticalLine />
      <SideEffectNode editable={editable}>{text}</SideEffectNode>
    </div>
  )
}

interface IValidationFlowSubtreeProps {
  onClickEdit: () => void
  editable: boolean
  saveFieldName: string | undefined
  attributeId?: number
  border?: boolean
}

export const ValidationFlowSubtree = ({
  onClickEdit,
  editable,
  saveFieldName,
  attributeId,
  border,
}: IValidationFlowSubtreeProps) => {
  const [contactAttribute, setContactAttribute] = React.useState<
    IContactAttribute | undefined
  >(undefined)
  const [loadingContactAttribute, setLoadingContactAttribute] = React.useState(
    Boolean(attributeId)
  )
  const [showFullValidationTree, setShowFullValidationTree] = React.useState(
    false
  )

  React.useEffect(() => {
    if (attributeId) {
      Api.fetchContactAttribute(attributeId)
        .then((res: AxiosResponse<IContactAttributeResponseData>) => {
          setLoadingContactAttribute(false)
          setContactAttribute({
            id: res.data.id,
            field: res.data.name,
            description: res.data.description,
            type: res.data.data_type,
            requires_auth: res.data.requires_auth || false,
            include_in_escalations: res.data.include_in_escalations || false,
            options: res.data.multi_choices,
          })
        })
        .catch(() => {
          toast('Problem fetching contact attribute', {
            type: 'error',
          })
        })
    }
  }, [attributeId])

  const handleToggleShowValidation = React.useCallback(
    () => setShowFullValidationTree(p => !p),
    [setShowFullValidationTree]
  )

  const validationMessage = React.useMemo(() => {
    switch (contactAttribute?.type) {
      case ContactAttributeType.PHONE:
        return `I didn’t quite get that. Please respond with a 10 digit phone number using only these characters: 0-9 () -`
      case ContactAttributeType.NUMBER:
        return `I didn't quite get that. Please respond with a number EX 5 or 5.0.`
      case ContactAttributeType.URL:
        return `I need a full URL. Don’t forget .com or .org, and double check for typos.`
      case ContactAttributeType.DATE:
        return 'Can you try that again? Please respond with a properly formatted date.'
      case ContactAttributeType.EMAIL:
        return `I didn't quite get that. Please include ‘@gmail.com’ or ‘@university.edu’ and double check for typos.`
      case ContactAttributeType.TEXT:
      default:
        return `I didn't quite get that. Please try again.`
    }
  }, [contactAttribute])

  return (
    <>
      <NodeSubtree
        parent={
          <HorizontalNode>
            <div className="d-flex">
              <SideEffectNode onClick={onClickEdit} editable={editable}>
                <div className="font-weight-bold">If response is invalid</div>
                <div>Request again</div>
              </SideEffectNode>
              <div className="text-green text-secondary-teal">
                <AHIcon
                  className="mx-2 icon-height-adjust"
                  name={showFullValidationTree ? 'expand_less' : 'expand_more'}
                />
                <span className="pointer" onClick={handleToggleShowValidation}>
                  {showFullValidationTree ? 'Hide' : 'Show'} Validation
                </span>
              </div>
            </div>
          </HorizontalNode>
        }>
        {showFullValidationTree && (
          <>
            <VerticalNode>
              <div
                className={classNames('rounded bg-white py-2 px-3 w-500px', {
                  'border border-color-bluegrey-30 rounded': border,
                })}>
                {loadingContactAttribute ? 'Loading...' : validationMessage}
              </div>
            </VerticalNode>

            <VerticalNode>
              <IncomingNode className="font-italic w-content">
                (Incoming Message from Contact)
              </IncomingNode>
              <NodeSubtree
                parent={
                  <HorizontalNode>
                    <SideEffectNode editable={false}>
                      <div className="font-weight-bold">
                        If response is invalid
                      </div>
                      <div>Skip to next message</div>
                    </SideEffectNode>
                  </HorizontalNode>
                }
              />
            </VerticalNode>

            <NodeSubtree
              className="last"
              parent={
                <HorizontalNode>
                  <SideEffectNode editable={false} className="mt-negative">
                    <div className="font-weight-bold">If response is valid</div>
                    <div className="d-flex align-center text-secondary-teal">
                      <AHIcon
                        name="arrow_forward"
                        className="icon-height-adjust"
                      />
                      Save responses as {saveFieldName}
                    </div>
                  </SideEffectNode>
                </HorizontalNode>
              }
            />
          </>
        )}
      </NodeSubtree>

      <NodeSubtree
        parent={
          <HorizontalNode>
            <SideEffectNode
              onClick={onClickEdit}
              editable={editable}
              className="mt-negative">
              <div className="font-weight-bold">If response is valid</div>
              <div className="d-flex align-center">
                Save responses as {saveFieldName}
              </div>
            </SideEffectNode>
          </HorizontalNode>
        }
      />
    </>
  )
}

interface ISaveActionFormProps {
  promptOptions: string[]
  onUpdateAction: (updatedAction: IExitAction) => void
  onDeleteAction: () => void
  promptType: PromptType
  onCancel: () => void
  action: IExitAction<ISaveExitActionArgs>
  actionIndex: number
  contactAttributes: IContactAttributeResponseData[]
  topLevelFields: ISaveableTopLevelContactFieldsResponseData
  saveFieldName: string
}

interface IMapperState {
  [k: string]: string
}

type ActionType = 'saveResponses' | 'saveStaticValue' | 'unsetField'

export const FormSchema = nope.object().shape({
  fieldValue: nope.mixed().when('actionType', (actionType: ActionType) => {
    if (actionType === 'saveStaticValue') {
      return ContactAttributeInputValidationSchema(true)
    }
    return nope.mixed().nullable(true)
  }),
})

interface ISaveActionForm {
  mapperState: IMapperState
  fieldValue: string | null | undefined
  selectedAttribute: IContactAttribute | undefined
  attributeType: ContactAttributeType
  actionType: ActionType
}

export const SaveActionForm = ({
  promptOptions,
  promptType,
  onCancel,
  action,
  onUpdateAction,
  onDeleteAction,
  actionIndex,
  contactAttributes,
  topLevelFields,
  saveFieldName,
}: ISaveActionFormProps) => {
  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch()

  const type = React.useMemo(() => {
    if (action.args.unset) {
      return 'unsetField'
    }
    if (action.args.value === undefined) {
      return 'saveResponse'
    }
    return 'saveStaticValue'
  }, [action])

  const attributes = React.useMemo(
    () => [
      ...mapResponseDataToAttributes(contactAttributes),
      ...(type === 'saveResponse'
        ? mapResponseDataToAttributes(topLevelFields)
        : []),
    ],
    [contactAttributes, topLevelFields, type]
  )

  const showMapper = React.useCallback(
    (selectedAttribute: IContactAttribute | undefined) => {
      if (type !== 'saveResponse') {
        return false
      }
      if (!selectedAttribute) {
        return false
      }
      return (
        Boolean(selectedAttribute.field) &&
        [PromptType.number, PromptType.boolean].includes(promptType)
      )
    },
    [type, promptType]
  )

  const getSelectedAttribute = (
    attributes: IContactAttribute[],
    name?: string
  ) => {
    return attributes.find((a: IContactAttribute) => {
      return a.field === name
    })
  }

  const showSelectValue = React.useCallback(
    (selectedAttribute: IContactAttribute | undefined) => {
      if (type !== 'saveStaticValue') {
        return false
      }
      return !!selectedAttribute
    },
    [type]
  )

  const handleSubmitForm = React.useCallback(
    ({ mapperState, fieldValue, selectedAttribute }: ISaveActionForm) => {
      if (selectedAttribute === undefined) {
        return
      }
      const newActionArgs: {
        field: string
        attributeId?: number
        contactAttributeValues?: IContactAttributeMapping | undefined
        value?: string | null | undefined
        unset?: boolean
      } = {
        field: selectedAttribute?.field,
        unset: action.args.unset,
      }
      if (type === 'saveStaticValue') {
        if (!fieldValue) {
          return
        }
        newActionArgs.value = fieldValue
      }
      if (!selectedAttribute.topLevelField) {
        newActionArgs.attributeId = selectedAttribute.id
      }
      if (promptType === PromptType.open) {
        onUpdateAction({
          ...action,
          reprompt: true,
          args: newActionArgs,
        })
      } else if (selectedAttribute) {
        const attributeMappingArray = Object.entries(mapperState).map(
          ([key, value]) => ({
            key: promptType === PromptType.boolean ? key.toLowerCase() : key,
            value,
          })
        )
        newActionArgs.contactAttributeValues = attributeMappingArray
        onUpdateAction({
          ...action,
          args: newActionArgs,
        })
      }
    },
    [type, promptType, action, onUpdateAction]
  )

  const filterForPromptType = React.useCallback(
    (contactAttribute: IContactAttributeResponseData) => {
      if (type !== 'saveResponse') {
        return true
      }
      if (promptType === PromptType.open) {
        return ['TEXT', 'NUMBER', 'DATE', 'EMAIL', 'PHONE'].includes(
          contactAttribute.data_type
        )
      }
      if (
        promptType === PromptType.boolean ||
        promptType === PromptType.number
      ) {
        return ['BOOLEAN', 'MULTI_CHOICE', 'TEXT'].includes(
          contactAttribute.data_type
        )
      }
      return true
    },
    [type, promptType]
  )

  const handleSearch = React.useCallback(
    (search: string) => {
      setLoading(true)
      return Promise.all([
        Api.fetchContactAttributes({
          search,
          pageSize: null,
          omitAuthEmail: true,
        }),
        Api.fetchSaveableTopLevelContactFields(search),
      ])
        .then(([attributeRes, topLevelFieldsRes]) => {
          setLoading(false)
          return [
            ...attributeRes.data.results,
            ...(isRight(topLevelFieldsRes) && type === 'saveResponse'
              ? topLevelFieldsRes.right
              : []),
          ]
            .filter(filterForPromptType)
            .map((r: IContactAttributeResponseData) => ({
              key: r.name,
              contactAttribute: r,
            }))
        })
        .catch(() => {
          toast('Problem fetching contact attributes.', {
            type: 'error',
          })
          return []
        })
    },
    [type, filterForPromptType]
  )

  const allowSave = React.useCallback(
    ({
      mapperValues,
      validationError,
      selectedAttribute,
    }: {
      mapperValues: { [k: string]: string }
      validationError?: boolean
      selectedAttribute?: IContactAttribute
    }): boolean => {
      if (!Boolean(selectedAttribute?.field)) {
        return false
      }
      if (validationError) {
        return false
      }
      if (
        type === 'saveResponse' &&
        [PromptType.number, PromptType.boolean].includes(promptType)
      ) {
        return Object.keys(mapperValues).some(
          (k: string) => mapperValues[k] !== ''
        )
      }
      return true
    },
    [type, promptType]
  )

  const phoneUpdateWarningMsg = (
    <span>
      Updating the contact's phone number within an SMS conversation means all
      subsequent messages will be sent to the new phone.{' '}
      <strong>Use with caution!</strong>
    </span>
  )

  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const initialAttributeMappingObject = React.useMemo(
    () =>
      (
        (action.args as ISaveExitActionArgs).contactAttributeValues ?? []
      ).reduce(
        (m, v) => ({
          ...m,
          [promptType === PromptType.boolean
            ? capitalize(v.key)
            : v.key]: v.value,
        }),
        {}
      ),
    [action.args, promptType]
  )

  const getInitialState = () => {
    const initialAttribute = getSelectedAttribute(attributes, saveFieldName)
    const actionType =
      action.args.value === undefined ? 'saveResponses' : 'saveStaticValue'
    return {
      mapperState: initialAttributeMappingObject,
      fieldValue: action.args.value,
      selectedAttribute: getSelectedAttribute(attributes, saveFieldName),
      attributeType: initialAttribute?.type || ContactAttributeType.TEXT,
      /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
      actionType: actionType as ActionType,
    }
  }

  return (
    <Formik<ISaveActionForm>
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={FormSchema}
      enableReinitialize={true}
      initialValues={getInitialState()}
      onSubmit={handleSubmitForm}>
      {({
        handleSubmit,
        values,
        handleChange,
        setTouched,
        touched,
        dirty,
        setFieldValue,
        errors,
      }) => {
        const setFormValues = (attribute: IContactAttribute) => {
          setFieldValue('selectedAttribute', attribute)
          setFieldValue('attributeType', attribute.type)
          let defaultValue = ''
          if (attribute.type === ContactAttributeType.BOOLEAN) {
            defaultValue = 'True'
          }
          if (attribute.type === ContactAttributeType.MULTI_CHOICE) {
            defaultValue = attribute.options?.[0].value || ''
          }
          setFieldValue('attributeValue', defaultValue)
        }

        const getTitle = () => {
          if (type === 'saveStaticValue') {
            return 'Set a Field Value'
          }
          if (type === 'unsetField') {
            return 'Unset a Field Value'
          }
          return 'Save Responses'
        }

        const handleChangeField = (e: {
          target: { value: string | string[]; name: string }
        }) => {
          const newAttribute = getSelectedAttribute(
            attributes,
            Array.isArray(e.target.value) ? e.target.value[0] : e.target.value
          )
          if (newAttribute) {
            setFormValues(newAttribute)
            setTouched({ ...touched, fieldValue: false })
            const getDefaultFieldValue = () => {
              if (!showSelectValue(newAttribute)) {
                return undefined
              }
              if (newAttribute?.type === ContactAttributeType.BOOLEAN) {
                return 'True'
              }
              if (newAttribute?.type === ContactAttributeType.MULTI_CHOICE) {
                const options = newAttribute?.options
                if (!options || options.length < 1) {
                  return undefined
                }
                return options[0].value
              }
              return undefined
            }
            setFieldValue('fieldValue', getDefaultFieldValue())
          }
        }

        return (
          <OutsideClickHandler
            containerClassName="border rounded background-white shadow-md mt-3"
            excludeClassname="contact-attributes-modal"
            onClickOutside={() => {
              if (
                allowSave({
                  mapperValues: values.mapperState,
                  selectedAttribute: values.selectedAttribute,
                  validationError: Object.keys(errors).length > 0,
                })
              ) {
                handleSubmit()
              }
            }}>
            <form
              className="d-flex flex-column w-500px justify-content-between"
              onSubmit={handleSubmit}>
              <div className="px-4 pt-4 pb-3">
                <div className="d-flex justify-content-between">
                  <h6>{getTitle()}</h6>
                  <AHIcon
                    name="delete"
                    onClick={onDeleteAction}
                    className="pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
                  />
                </div>
                <p className="caption mt-4 mb-1">Select Contact Field</p>
                <AutocompleteWithCreateModal
                  omitOption={type !== 'saveResponse'}
                  modalClassname="contact-attributes-modal"
                  id={`exitAction${actionIndex}`}
                  name={`exitAction${actionIndex}`}
                  search={handleSearch}
                  create={(req: ICreateContactAttributeForm) => {
                    return Api.updateContactAttribute({
                      name: req.name,
                      /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                      data_type: req.fieldType.value as ContactAttributeType,
                    })
                      .then(
                        (res: AxiosResponse<IContactAttributeResponseData>) => {
                          const newAttribute = mapResponseDataToAttributes([
                            res.data,
                          ])[0]
                          handleSubmitForm({
                            ...values,
                            selectedAttribute: newAttribute,
                          })
                          return {
                            key: 'selectedAttribute',
                            contactAttribute: res.data,
                          }
                        }
                      )
                      .then(res => {
                        getAllContactAttributesAsync(dispatch)()
                        return res
                      })
                      .catch(() => {
                        toast('Problem creating contact attribute', {
                          type: 'error',
                        })
                        return undefined
                      })
                  }}
                  onChange={handleChangeField}
                  map={form => ({ name: form.name, fieldType: form.fieldType })}
                  messages={{
                    addOption: 'Create New Field',
                    loading: 'Loading Fields',
                    noOptions: 'No Fields',
                  }}
                  components={{
                    form: CreateContactAttributeForm,
                    option: ContactAttributeOption,
                  }}
                  value={values.selectedAttribute?.field}
                />
                {values.selectedAttribute?.topLevelField &&
                values.selectedAttribute?.field === 'phone' ? (
                  <InfoAlert
                    className="mt-2"
                    children={phoneUpdateWarningMsg}
                  />
                ) : null}
                {showSelectValue(values.selectedAttribute) && !loading && (
                  <SelectValue
                    fieldName={values.selectedAttribute?.field}
                    valueValidationError={errors.fieldValue || null}
                    value={values.fieldValue}
                    type={values.selectedAttribute?.type}
                    onDayChange={(e: Date) => {
                      setFieldValue(
                        'fieldValue',
                        moment(e).format(moment.HTML5_FMT.DATE)
                      )
                      setTouched({ ...touched, fieldValue: true })
                    }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                    ) => {
                      setFieldValue('fieldValue', e.target.value)
                      setTouched({ ...touched, fieldValue: true })
                    }}
                    options={values.selectedAttribute?.options}
                  />
                )}
                {showMapper(values.selectedAttribute) && !loading && (
                  <SaveActionFieldMapper
                    promptType={promptType}
                    promptOptions={promptOptions}
                    contactAttribute={values.selectedAttribute}
                    validationError={
                      dirty &&
                      !allowSave({
                        mapperValues: values.mapperState,
                        selectedAttribute: values.selectedAttribute,
                        validationError: Object.keys(errors).length > 0,
                      })
                    }
                    mapping={values.mapperState}
                    handleFieldChange={handleChange}
                  />
                )}
              </div>
              <hr className="w-100 m-0" />
              <div className="px-4 py-3">
                <Button
                  className="mr-2 btn btn-primary bg-secondary-teal"
                  disabled={
                    !allowSave({
                      mapperValues: values.mapperState,
                      selectedAttribute: values.selectedAttribute,
                      validationError: Object.keys(errors).length > 0,
                    })
                  }
                  type="submit">
                  Save
                </Button>
                <Button
                  onClick={onCancel}
                  className="text-blue-grey-501 text-decoration-none"
                  color="link">
                  Cancel
                </Button>
              </div>
            </form>
          </OutsideClickHandler>
        )
      }}
    </Formik>
  )
}

interface ISaveActionFieldMapperProps {
  promptOptions?: string[]
  promptType: PromptType
  validationError: boolean
  handleFieldChange: (
    a: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void
  contactAttribute?: IContactAttribute
  mapping: { [k: string]: string }
}

export const SaveActionFieldMapper = ({
  promptType,
  promptOptions,
  validationError,
  contactAttribute,
  handleFieldChange,
  mapping,
}: ISaveActionFieldMapperProps) => {
  const prompts = React.useMemo(() => {
    if (promptType === 'Number') {
      return promptOptions?.map(o => o)
    } else if (promptType === 'Boolean') {
      return ['Yes', 'No']
    } else {
      return []
    }
  }, [promptOptions, promptType])

  const attributeChoices = React.useMemo(() => {
    if (contactAttribute === undefined) {
      return []
    }
    if (contactAttribute.type === 'BOOLEAN') {
      return ['Yes', 'No']
    }
    if (contactAttribute.type === 'MULTI_CHOICE') {
      return contactAttribute.options?.map(c => c.value) || []
    }
    return []
  }, [contactAttribute])
  return (
    <div className="mt-4">
      <p className="pr-3">
        This is a multi-choice field. Please choose how each response should be
        named when saved to a profile.
      </p>
      {validationError && (
        <AlertBanner
          className="mb-1rem"
          type={AlertType.Danger}
          subtitle="Please save at least one response"
        />
      )}
      <div className="d-flex mb-2">
        <div className="flex-even error">
          <span className="caption">Response</span>
        </div>
        <div className="flex-even">
          <span className="caption">Option</span>
        </div>
      </div>
      {prompts?.map((p: string, i: number) => (
        <SaveActionField
          key={p}
          rank={i}
          prompt={p}
          handleFieldChange={handleFieldChange}
          attributeChoices={attributeChoices}
          openInput={contactAttribute?.type === 'TEXT'}
          error={validationError}
          promptType={promptType}
          selectedChoice={
            promptType === 'Boolean' ? mapping[p] : mapping[`${i + 1}`]
          }
        />
      ))}
    </div>
  )
}

interface ISaveActionFieldProps {
  prompt: string
  rank: number
  promptType: string
  openInput: boolean
  error: boolean
  handleFieldChange: (
    a: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void
  attributeChoices: string[]
  selectedChoice: string | undefined
}

const SaveActionField = ({
  rank,
  prompt,
  attributeChoices,
  selectedChoice,
  error,
  handleFieldChange,
  promptType,
  openInput,
}: ISaveActionFieldProps) => {
  return (
    <div className="d-flex">
      <div className={classNames('flex-even', { error })}>
        <span>
          [{rank + 1}]: {prompt}
        </span>
      </div>
      <div className="flex-even mb-3">
        {openInput ? (
          <Input
            value={selectedChoice}
            name={
              promptType === 'Boolean'
                ? 'mapperState.' + prompt
                : `mapperState.${rank + 1}`
            }
            onChange={handleFieldChange}
          />
        ) : (
          <HouseSelect
            className="mr-3 border-none"
            error={error}
            name={
              promptType === 'Boolean'
                ? 'mapperState.' + prompt
                : `mapperState.${rank + 1}`
            }
            onChange={handleFieldChange}
            value={selectedChoice}>
            <option value="">Select an attribute</option>
            {attributeChoices.map((choice: string) => (
              <option key={choice} value={choice}>
                {choice}
              </option>
            ))}
          </HouseSelect>
        )}
      </div>
    </div>
  )
}

const SelectValue = ({
  valueValidationError,
  type,
  value,
  onDayChange,
  onChange,
  fieldName,
  options,
}: {
  valueValidationError: string | null
  type?: ContactAttributeType
  value: string | null | undefined
  onDayChange: (day: Date) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  fieldName?: string
  options?: IContactAttributeOption[]
}) => {
  let instructions = ''
  switch (type) {
    case ContactAttributeType.DATE:
      instructions = 'Choose a date'
      break
    case ContactAttributeType.BOOLEAN:
    case ContactAttributeType.MULTI_CHOICE:
      instructions = 'Choose a value'
      break
    case ContactAttributeType.TEXT:
    case ContactAttributeType.PHONE:
    case ContactAttributeType.EMAIL:
    case ContactAttributeType.URL:
    default:
      instructions = 'Enter a value'
      break
  }

  const dateFormat = useSelector(getInstitutionDateFormat)

  const input = () => {
    if (type === ContactAttributeType.DATE) {
      return (
        <ReadOnlyDatePicker
          value={
            value
              ? strftime(dateFormat, parse(value, 'yyyy-MM-dd', new Date()))
              : undefined
          }
          onDayChange={onDayChange}
          classNames={{
            container: 'DayPickerInput-Container mr-1',
            overlayWrapper: 'DayPickerInput-OverlayWrapper',
            overlay: 'DayPickerInput-Overlay',
          }}
        />
      )
    }
    if (
      type &&
      [
        ContactAttributeType.MULTI_CHOICE,
        ContactAttributeType.BOOLEAN,
      ].includes(type)
    ) {
      const selectOptions =
        type === ContactAttributeType.MULTI_CHOICE
          ? options
          : [
              { id: 0, value: 'True' },
              { id: 1, value: 'False' },
            ]
      return (
        <HouseSelect
          className="mr-3 border-none"
          error={!!valueValidationError}
          onChange={onChange}
          value={value || ''}>
          {selectOptions?.map((choice: IContactAttributeOption) => (
            <option key={choice.value} value={choice.value}>
              {choice.value}
            </option>
          ))}
        </HouseSelect>
      )
    }
    return (
      <LabeledTextInput
        id="fieldValueInput"
        label={fieldName || 'Value'}
        onChange={onChange}
        error={!!valueValidationError}
        value={value || ''}
      />
    )
  }

  return (
    <div className="mt-4">
      <p className="pr-3">{instructions}</p>
      {input()}
      {!!valueValidationError && (
        <div className="text-danger small">{valueValidationError}</div>
      )}
    </div>
  )
}
